import React, { useState } from "react";

import { Button } from "../../src/components/Button";
import { LayoutWithBottomBtn } from "../../src/layouts/LayoutWithBottomBtn";
import { JustifyBetween } from "../../src/components/JustifyBetween";
import { useLocation, useNavigate } from "react-router-dom";
import { BackArrowButton } from "../../src/components/BackArrowButton";
import { useStore } from "src/store/store";
import { SelectTxnOptionParams } from "./TransactionOption";
import useGetRecipients from "src/hooks/useRecipients";
import { Recipient } from "src/services/response-types/recipientsRes";
import FlexibleMiddleContent from "src/layouts/FlexibleMiddleContent";

export type RecepientSourceParams = SelectTxnOptionParams & {
    recipient_id: string;
};

export function PossibleRecipients() {
    let { state }: { state: any } = useLocation();
    const [selectedRecipient, setSelectedRecipient] = useState<
        Recipient | undefined
    >();
    let paramsData: SelectTxnOptionParams = state;
    const accessToken = useStore((state) => state.token?.access_token);
    const navigate = useNavigate();
    const { getRecipientsRequest } = useGetRecipients({
        accessToken: accessToken!,
    });

    const onSubmit = () => {
        if (selectedRecipient) {
            navigate("/customer/transact/step-4/enter-amount", {
                state: {
                    ...paramsData,
                    recipient_id: `${selectedRecipient.id}`,
                } as RecepientSourceParams,
            });
        }
    };

    return (
        <LayoutWithBottomBtn
            AppBarContent={
                <div className="flex p-5 justify-start items-center">
                    <BackArrowButton />
                    <p className="text-white font-semibold text-2xl pl-5">
                        Select Recipient
                    </p>
                </div>
            }
            MiddleContent={
                <FlexibleMiddleContent
                    BodyContent={
                        <div className="w-full">
                            {(getRecipientsRequest.data?.data || []).map(
                                (recipient, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center  justify-between shadow-lg m-4 rounded-lg"
                                    >
                                        <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                                            <div className="p-4 w-[100%] flex border-b-2">
                                                <JustifyBetween
                                                    Left={
                                                        <div className="flex items-center">
                                                            <div>
                                                                <p className="text-[#0044AA] font-semibold text-lg">
                                                                    {
                                                                        recipient.first_name
                                                                    }
                                                                </p>
                                                                <p className="text-gray-400 font-normal text-base mt-1">
                                                                    {`${recipient.mobile_number}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    Right={
                                                        <input
                                                            className="form-check-input appearance-none rounded-full h-4 w-4 border-2 border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                            type="radio"
                                                            onChange={() => {
                                                                setSelectedRecipient(
                                                                    recipient
                                                                );
                                                            }}
                                                            name="sending_to"
                                                            value={recipient.id}
                                                            id="flexRadioDefault10"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    }
                />
            }
            BottomButtons={
                <Button
                    label="Continue"
                    className="flex-1"
                    bgColor="[#0044AA]"
                    textColor="white"
                    type="submit"
                    onClick={() => {
                        onSubmit();
                    }}
                />
            }
        />
    );
}
