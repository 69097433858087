import React, { useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { Button } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { AmountFlag } from '../../src/components/AmountFlag';
import { JustifyBetween } from '../../src/components/JustifyBetween';
import { FieldCard } from '../../src/components/FieldCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import useConvertAmount from '../../src/hooks/useConvertAmount';
import { ConvertAmountInputMap } from '../../src/services/response-types/settingsRes';
import { RecepientSourceParams } from './RecepientSource';
import { useStore } from '../../src/store/store';
import { separateNumberWithCommas } from 'src/utils/helpers';

interface FormData {
  sendAmount: number;
  receiveAmount: number;
}

interface EnterAmount {
  amount: number;
  amount_to_receive?: number;
  service_fee: number;
  balance_after: number;
}

export type EnterAmountParams = RecepientSourceParams & EnterAmount;

export function EnterAmount() {
  let navigate = useNavigate();
  const user = useStore((state) => state.user);
  const submitFormRef = useRef<HTMLFormElement>();
  let { state }: { state: any } = useLocation();
  const transactionData = useStore((state) => state.transactionData);
  const paymentSettingsData = useStore((state) => state.paymentSettings);
  const setTransactionData = useStore((state) => state.setTransactionData);
  let paramsData: RecepientSourceParams = state;
  const { register, handleSubmit, watch, setValue, control } =
    useForm<FormData>({
      defaultValues: {
        sendAmount: transactionData?.amount || 0,
        receiveAmount: transactionData?.amount_to_receive || 0,
      },
    });

  const watchAllFields = watch();

  console.log('transactionData?.amount:', transactionData?.amount);

  const data: ConvertAmountInputMap = {
    defaultAmount: Number(watchAllFields.sendAmount),
    receiveAmount: Number(watchAllFields.receiveAmount),
    defaultDestinationCurrencyCode: paramsData.sending_to_currency as any,
    defaultPaymentMethodId: paramsData.payment_method_id,
    defaultSelectedCurrencyCode: user?.country.currency_code as any,
    paymentSettings: paymentSettingsData!,
    walletBalance: paramsData.isCalculateOption
      ? 1_000_000_000_000_000
      : Number(user!.wallet.balance),
  };

  console.log(data);

  const {
    appExchangeRate,
    destinationAmount,
    amount,
    serviceFee,
    walletBalance,
    defaultDestinationCurrencyCode,
    defaultSelectedCurrencyCode,
  } = useConvertAmount(data);

  console.log(watchAllFields.sendAmount);
  console.log('destinationAmount:', destinationAmount);

  useEffect(() => {
    setValue('receiveAmount', destinationAmount);
  }, [destinationAmount]);

  useEffect(() => {
    setValue('sendAmount', amount);
  }, [amount]);

  const onSubmit = handleSubmit((data: FormData) => {
    let navStateData = {
      amount: data.sendAmount,
      amount_to_receive: data.receiveAmount,
      service_fee: serviceFee,
      balance_after: walletBalance,
      ...paramsData,
    } as EnterAmountParams;

    setTransactionData(navStateData);

    navigate('/customer/transact/step-5/transaction-summary', {
      state: navStateData,
    });
  });

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">Enter Amount</p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div>
              <div>
                <div className="w-full flex items-center justify-between">
                  <form
                    action=""
                    onSubmit={onSubmit}
                    // @ts-ignore
                    ref={submitFormRef}
                    className="w-full"
                  >
                    <div className="flex items-center h-[100%] justify-center ">
                      {/* <img
                                                        className="w-10 h-10 rounded-lg"
                                                        src={user.avatar}
                                                        alt={user.name}
                                                    /> */}
                      <div className="flex-1">
                        <FieldCard
                          FieldContent={
                            <div className="w-full">
                              <AmountFlag
                                countryCode={user?.country.code!}
                                label="You Send"
                                name="sendAmount"
                                control={control}
                                type="number"
                                currency={defaultSelectedCurrencyCode}
                                register={register}
                              />
                            </div>
                          }
                        />

                        <div className="p-4 m-4">
                          <JustifyBetween
                            Left={
                              <p className="text-[#0044AA] font-normal text-base">
                                {'Exchange Rate'}
                              </p>
                            }
                            Right={
                              <p className="text-[#0044AA] font-normal text-base">
                                {appExchangeRate}
                              </p>
                            }
                          />
                        </div>

                        <FieldCard
                          FieldContent={
                            <div className="w-full">
                              <AmountFlag
                                countryCode={paramsData.sending_to_country_code}
                                label="They Receive"
                                name="receiveAmount"
                                type="number"
                                control={control}
                                currency={defaultDestinationCurrencyCode}
                                register={register}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="p-4 m-4">
                  <JustifyBetween
                    Left={
                      <p className="text-[#0044AA] font-normal text-base">
                        {'Service Fee'}
                      </p>
                    }
                    Right={
                      <p className="text-[#0044AA] font-normal text-base">
                        {separateNumberWithCommas(serviceFee || '0')}
                      </p>
                    }
                  />
                  <JustifyBetween
                    className="mt-6"
                    Left={
                      <p className="text-[#0044AA] font-normal text-base">
                        {'Total'}
                      </p>
                    }
                    Right={
                      <p className="text-[#0044AA] font-normal text-base">
                        {separateNumberWithCommas((serviceFee || 0) + amount)}
                      </p>
                    }
                  />
                </div>
                {!paramsData.isCalculateOption && (
                  <div className="p-4 mb-3 flex items-center bg-white shadow rounded-lg cursor-pointer m-4">
                    <JustifyBetween
                      Left={
                        <p className="text-[#0044AA] font-semibold text-xl">
                          {'Balance after transaction'}
                        </p>
                      }
                      Right={
                        <p className="text-[#0044AA] font-semibold text-xl">
                          {separateNumberWithCommas(walletBalance || '0')}
                        </p>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      }
      BottomButtons={
        !paramsData.isCalculateOption ? (
          <Button
            label="Continue"
            className="flex-1"
            textColor="white"
            bgColor="[#0044AA]"
            type="submit"
            onClick={() => {
              if (submitFormRef && submitFormRef.current) {
                // submitFormRef.current.preventDefault();
                console.log('Dispatching event');

                submitFormRef.current.dispatchEvent(
                  new Event('submit', {
                    cancelable: true,
                    bubbles: true,
                  })
                );
              }
            }}
          />
        ) : (
          <></>
        )
      }
    />
  );
}
