import React from "react";

export function AppBar({ AppBarContent }: { AppBarContent: JSX.Element }) {
    return (
        <section
            id="bottom-navigation"
            className="w-[100vw] md:w-[600px] block inset-x-0 top-0 z-10 bg-white shadow m-auto"
        >
            <div className="h-[100%] w-[100%] bg-[#0044AA] flex flex-col px-[20px] rounded-br-[20px] rounded-bl-[20px]">
                <div className="my-auto ">{AppBarContent}</div>
            </div>
        </section>
    );
}
