import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TextInput } from '../components/TextInput';
import { AuthLayout } from '../../src/layouts/AuthLayout';
import { PhoneInput } from 'src/components/PhoneInput';
import { useStore } from 'src/store/store';
import { useQuery } from 'react-query';
import { registerUser } from 'src/services/account';

interface FormData {
  phone_code: string;
  first_name: string;
  last_name: string;
  country: string;
  mobile_number: string;
  password: string;
}

export function RegisterPage() {
  const { register, handleSubmit, getValues, setValue, control } =
    useForm<FormData>();
  const countries = useStore((state) => state.countries);
  let navigate = useNavigate();

  console.log(countries);

  useWatch({ control, name: 'phone_code' });
  useWatch({ control, name: 'mobile_number' });

  const onSubmit = handleSubmit(() => {
    registerUserRequest.refetch({ throwOnError: true });
  });

  const registerUserRequest = useQuery(
    'registerUser',
    async () => {
      console.log({
        ...getValues(),
        country: (countries || []).find(
          (c: any) => c.phone_code == getValues().phone_code
        )?.code,
      });

      return registerUser({
        body: {
          ...getValues(),
          country: (countries || []).find(
            (c: any) => c.phone_code == getValues().phone_code
          )?.code,
        },
      });
    },
    {
      enabled: false,
      retry: false,
      onSuccess: async (_data: any) => {
        navigate('/login');
      },
    }
  );

  return (
    <AuthLayout
      title="Welcome Back"
      subtitle="Sign in to continue"
      body={
        <form action="" className="space-y-6" onSubmit={onSubmit}>
          <PhoneInput
            keyVal="signin-phone-input-key"
            label="Phone Number"
            name="mobile_number"
            type="number"
            textColor="white"
            inputClassName="text-white"
            phoneCode={getValues()?.phone_code || ''}
            register={register}
            setValue={setValue}
          />
          <TextInput
            label="First Name"
            name="first_name"
            type="text"
            textColor="white"
            inputClassName="text-white"
            register={register}
          />
          <TextInput
            label="Last Name"
            name="last_name"
            type="text"
            textColor="white"
            inputClassName="text-white"
            register={register}
          />
          <TextInput
            label="Password"
            name="password"
            type="password"
            textColor="white"
            inputClassName="text-white"
            register={register}
          />
          <div>
            <button className="w-full py-2 px-4 bg-[#0044AA] rounded-md text-white text-sm">
              Submit
            </button>
          </div>
        </form>
      }
    />
  );
}
