import React, { useEffect, useState } from 'react';

import { Button } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { JustifyBetween } from '../../src/components/JustifyBetween';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { SelectCountryParams } from './SelectCountry';
import usePaymentMethods from 'src/hooks/usePaymentMethods';
import { PaymentMethod } from '../../src/services/response-types/settingsRes';

// @ts-ignore
import CoinPileSvg from '../assets/svgs/coin_pile.js';
import { useStore } from 'src/store/store';

export interface SelectedTxnOption {
  code: 'sendMoney' | 'utility';
  text: string;
}

export interface SelectTxnOption {
  payment_method_code: string;
  sending_to: string;
  phone_code: number;
  payment_method_id: number;
  payment_method_category_code: string;
  payment_method_name: string;
  isCalculateOption: boolean;
}

export type SelectTxnOptionParams = SelectTxnOption & SelectCountryParams;

export function TransactionOption() {
  let navigate = useNavigate();
  let { state }: { state: any } = useLocation();
  const userCountryCode = useStore((state) => state.user?.country_code);
  const setTransactionData = useStore((state) => state.setTransactionData);
  const transactionData = useStore((state) => state.transactionData);
  const [selectedTxnOption, setSelectedTxnOption] = useState<PaymentMethod>({
    name: transactionData?.payment_method_name!,
    // @ts-ignore
    payment_method_category_code:
      transactionData?.payment_method_category_code!,
    code: transactionData?.payment_method_code!,
    id: transactionData?.payment_method_id!,
  });
  let paramsData: SelectCountryParams = state;
  const [selectedOptionCategory, setSelectedOptionCategory] = useState<
    SelectedTxnOption['code']
  >(
    (paramsData.transferOptionsCategory as SelectedTxnOption['code']) ||
      'sendMoney'
  );

  const { remittanceOptions, utilities } = usePaymentMethods({
    // @ts-ignore
    baseCountryCode: userCountryCode,
    receiverCountryCode: paramsData.sending_to,
    paymentSettings: paramsData.paymentSettings,
  });

  const optionCategories: SelectedTxnOption[] = [
    {
      code: 'sendMoney',
      text: 'Send Money',
    },
    {
      code: 'utility',
      text: 'Utilities',
    },
  ];

  useEffect(() => {}, [selectedTxnOption]);

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Choose Transaction Option
          </p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="bg-white">
              <nav className="flex flex-row">
                {optionCategories.map((optionCategory, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedOptionCategory(optionCategory.code);
                    }}
                    className={`${'flex-1 py-4 px-6 block hover:text-blue-500 focus:outline-none text-blue-500 font-medium'} ${
                      optionCategory.code == selectedOptionCategory &&
                      'border-b-2  border-blue-500'
                    }`}
                  >
                    {optionCategory.text}
                  </button>
                ))}
              </nav>
            </div>
            <div className="w-full">
              {(selectedOptionCategory == 'sendMoney'
                ? remittanceOptions
                : utilities
              ).map((option, index) => (
                <div
                  key={index}
                  className="flex items-center  justify-between shadow-lg m-4 rounded-lg"
                >
                  <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                    <div className="p-4 w-[100%] flex border-b-2">
                      <JustifyBetween
                        Left={
                          <div className="flex items-center">
                            <div className="-ml-4 mr-4">
                              <div className="h-[30px] w-[50px] justify-center items-center ml-4">
                                <CoinPileSvg fill="#0044AA" />
                              </div>
                            </div>
                            <div>
                              <p className="text-[#0044AA] font-normal text-lg">
                                {option.name}
                              </p>
                            </div>
                          </div>
                        }
                        Right={
                          <input
                            className={`form-check-input appearance-none rounded-full h-4 w-4 border-2 ${
                              option.code != selectedTxnOption.code
                                ? 'border-gray-300 bg-white'
                                : 'bg-blue-600 border-blue-600'
                            } focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault10"
                            onChange={() => {
                              setSelectedTxnOption(option);
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </>
      }
      BottomButtons={
        <Button
          label="Continue"
          className="flex-1"
          bgColor="[#0044AA]"
          textColor="white"
          type="submit"
          onClick={() => {
            if (selectedTxnOption) {
              let navStateData = {
                payment_method_name: selectedTxnOption.name,
                payment_method_category_code:
                  selectedTxnOption.payment_method_category_code,
                payment_method_code: selectedTxnOption.code,
                payment_method_id: selectedTxnOption.id,
                ...paramsData,
              } as Partial<SelectTxnOptionParams>;

              setTransactionData(navStateData);
              navigate('/customer/transact/step-4/enter-amount', {
                state: navStateData,
              });
              if (paramsData.isCalculateOption) {
              } else {
                navigate('/customer/transact/step-3/select-recipient', {
                  state: navStateData,
                });
              }
            }
          }}
        />
      }
    />
  );
}
