import React from "react";

// @ts-ignore
import CoinPileSvg from "../assets/svgs/coin_pile.js";
// @ts-ignore
import TransactSvg from "../assets/svgs/transaction.js";
// @ts-ignore
import UserIcon from "../assets/svgs/user_icon.js";
// @ts-ignore
import UtilityIcon from "../assets/svgs/utility_icon.js";
// @ts-ignore
import HistoryIcon from "../assets/svgs/history_icon.js";
// @ts-ignore
import HomeIcon from "../assets/svgs/home_icon.js";
// @ts-ignore
import RecepientIcon from "../assets/svgs/recipient_icon.js";
import { AppBar } from "../../src/components/AppBarContent";

export function AppBarAndMiddleContent({
    AppBarContent,
    MiddleContent,
}: {
    AppBarContent: JSX.Element;
    MiddleContent: JSX.Element;
}) {
    return (
        <div className="from-[#0044AA] to-[#001F4F] h-[calc(100vh-100px)] w-[100vw] md:w-[600px]">
            <div className="h-full bg-gradient-to-b flex flex-col items-center">
                <AppBar AppBarContent={AppBarContent} />
                <section className="w-[100vw] md:w-[600px] inset-x-0 z-10 bg-white shadow m-auto flex-1">
                    {MiddleContent}
                </section>

                {/* if shown only tablet/mobile */}
                {/* <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">  */}
            </div>
        </div>
    );
}
