import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { updateWallet } from "src/services/account";
import { WithAccessTokenOnly } from "src/services/request-types/general";
import { User } from "../../src/types/account";
import { useStore } from "../../src/store/store";
import { separateNumberWithCommas } from "../../src/utils/helpers";

interface SetUserType {
    updateWalletBalanceCallBack: (val: User) => void;
    user: User;
}

export function BalanceBar() {
    const { walletBalance, walletCurrency, accessToken, setUser, user } =
        useStore((state) => {
            return {
                walletBalance: state.user?.wallet.balance || 0,
                walletCurrency: state.user?.wallet.currency.symbol || "---",
                accessToken: state.token?.access_token || "",
                setUser: state.setUser,
                user: state.user,
            };
        });

    const { refetch } = useUpdateBalance({
        accessToken,
        updateWalletBalanceCallBack: setUser,
        user: user!,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            console.log("refetching: wallet");

            refetch({ throwOnError: true });
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="my-5">
            <p className="text-white text-base text-opacity-50">
                Current Balance
            </p>
            <p className="text-white font-semibold text-2xl">
                {`${walletCurrency} ${separateNumberWithCommas(walletBalance)}`}
            </p>
        </div>
    );
}

export default function useUpdateBalance({
    accessToken,
    updateWalletBalanceCallBack,
    user,
}: WithAccessTokenOnly & SetUserType) {
    const updateWalletRequest = useQuery(
        "updateWallet",
        () =>
            updateWallet({
                accessToken: accessToken!,
            }),
        {
            enabled: false,
            onSuccess: (data) => {
                updateWalletBalanceCallBack({
                    ...user,
                    wallet: { ...user.wallet, ...data.wallet },
                });
            },
        }
    );

    return updateWalletRequest;
}
