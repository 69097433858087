import React from 'react';
import { BackArrowButton } from '../components/BackArrowButton';
import { LayoutWithoutBottonBtnAndNav } from '../layouts/LayoutWithoutBottonBtnAndNav';

import { JustifyBetween } from '../components/JustifyBetween';

export function AboutPage() {
  return (
    <LayoutWithoutBottonBtnAndNav
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">About</p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-full">
              <div className="flex items-center  justify-between shadow-lg m-4 rounded-lg">
                <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                  <div className="p-4 w-[100%] flex border-b-2 cursor-pointer">
                    CowrieX allows the migrant diaspora fast, easy, and secure
                    money transfers to family and friends back home.
                    <br />
                    The name “Cowrie” is derived from Cowrie shells. Cowrie
                    shells, especially Monetaria moneta, were used for centuries
                    as currency by native Africans.
                  </div>

                  <div className="p-4 w-[100%] flex border-b-2 cursor-pointer">
                    <JustifyBetween
                      Left={
                        <p className="text-[#0044AA] font-semibold text-base">
                          {'Get in touch'}
                        </p>
                      }
                      Right={
                        <p className="text-[#0044AA] font-normal text-base">
                          {'+27 83 344 6965'}
                        </p>
                      }
                    />
                  </div>
                  {/* <div className="p-4 w-[100%] flex border-b-2">
                                        <JustifyBetween
                                            Left={
                                                <p className="text-[#0044AA] font-normal text-base">
                                                    {"About"}
                                                </p>
                                            }
                                            Right={
                                                <p className="text-[#0044AA] font-normal text-base">
                                                    {"arrow_right"}
                                                </p>
                                            }
                                        />
                                    </div> */}
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}
