import React, { useState } from 'react';

// @ts-ignore
import CoinPileSvg from '../assets/svgs/coin_pile.js';
// @ts-ignore
import TransactSvg from '../assets/svgs/transaction.js';
// @ts-ignore
import UserIcon from '../assets/svgs/user_icon.js';
// @ts-ignore
import UtilityIcon from '../assets/svgs/utility_icon.js';
// @ts-ignore
import HistoryIcon from '../assets/svgs/history_icon.js';
// @ts-ignore
import HomeIcon from '../assets/svgs/home_icon.js';
// @ts-ignore
import RecepientIcon from '../assets/svgs/recipient_icon.js';
import { Home } from './TabViewPage';
import { ReceipientsPage } from './ReceipientsPage';
import { TransactionHistory } from './TransactionHistory';
import { Account } from './AccountPage';
import { useNavigate } from 'react-router-dom';
import { SelectedTxnOption } from './TransactionOption.js';

export function TabPageSwitcher() {
  const [selectedBottomItem, setSelectedBottomItem] = useState(0);
  const navigate = useNavigate();

  const bottomNavigationItems = [
    {
      name: 'Home',
      isFloating: false,
      svg: <HomeIcon color="#0044AA" />,
    },
    {
      name: 'Recipients',
      isFloating: false,
      svg: <RecepientIcon color="#0044AA" />,
    },
    {
      name: 'Transact',
      isFloating: true,
      svg: <TransactSvg fill="white" />,
    },
    {
      name: 'History',
      isFloating: false,
      svg: <HistoryIcon color="#0044AA" />,
    },
    {
      name: 'Account',
      isFloating: false,
      svg: <UserIcon color="#0044AA" />,
    },
  ];

  function updateSelectedBottomTab(index: number) {
    setSelectedBottomItem(index);
  }

  return (
    <div className="h-full bg-gradient-to-b from-[#0044AA] to-[#001F4F] flex flex-col items-center">
      <div className="flex flex-1">
        {selectedBottomItem == 0 && <Home />}
        {selectedBottomItem == 1 && <ReceipientsPage />}
        {selectedBottomItem == 2 && <></>}
        {selectedBottomItem == 3 && <TransactionHistory />}
        {selectedBottomItem == 4 && <Account />}
      </div>
      <div className="h-[100px] w-[100vw] md:w-[600px] bg-white"></div>
      <section
        id="bottom-navigation"
        className="w-[100vw] md:w-[600px] block fixed inset-x-0 bottom-0 z-10 bg-white m-auto flex-1 border-t-2 mb-4"

        // className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
      >
        <div id="tabs" className="flex justify-between">
          {bottomNavigationItems.map((bottomNavigationItem, index) => (
            <div
              key={bottomNavigationItem.name}
              onClick={() => {
                if (bottomNavigationItem.isFloating) {
                  navigate('/customer/transact/step-1/select-country', {
                    state: {
                      transferOptionsCategory:
                        'sendMoney' as SelectedTxnOption['code'],
                      isCalculateOption: false,
                    },
                  });
                  return;
                }
                updateSelectedBottomTab(index);
              }}
              className={`w-full ${
                selectedBottomItem == index && 'text-[#0044AA]'
              } hover:text-[#0044AA] justify-center inline-block text-center pt-2 pb-1 relative`}
            >
              {bottomNavigationItem.isFloating && (
                <div
                  style={{
                    width: '65px',
                    height: '65px',
                    backgroundColor: '#0044AA',
                    position: 'absolute',
                    top: -35,
                    borderRadius: '50%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="inline-block mb-1 left-[calc(50%-30px)]"
                >
                  {bottomNavigationItem.svg}
                </div>
              )}
              {!bottomNavigationItem.isFloating && (
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                  className="inline-block mb-1"
                >
                  {bottomNavigationItem.svg}
                </div>
              )}
              <div className="h-[10px]"></div>
              <div className="tab tab-home text-xs bottom-0 absolute mt-4 w-[100%]">
                {bottomNavigationItem.name}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
