import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AuthLayout } from '../../src/layouts/AuthLayout';
import { useQuery } from 'react-query';
import { passwordReset } from '../../src/services/account';
import { useNavigate } from 'react-router-dom';
import ModalSheet from '../../src/components/BottomSheet';
import { Button, StatefulButton } from '../../src/components/Button';

import { PhoneInput } from '../../src/components/PhoneInput';

// @ts-ignore
import RefreshIconSvg from '../assets/svgs/refresh_icon.js';
// @ts-ignore
import CloseIconSvg from '../assets/svgs/close.js';

interface FormData {
  mobile_number: string;
  phone_code: string;
}

export function RequestPasswordReset() {
  const { register, handleSubmit, getValues, setValue, control } =
    useForm<FormData>();
  let navigate = useNavigate();
  const [showModalSheet, setShowModalSheet] = useState(false);

  useWatch({ control, name: 'phone_code' });
  useWatch({ control, name: 'mobile_number' });

  const onSubmit = handleSubmit((_data: FormData) => {
    console.log(_data);
    passwordResetRequest.refetch({ throwOnError: true });
  });

  const passwordResetRequest = useQuery(
    'passwordReset',
    async () => passwordReset({ body: getValues() }),
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (_data: any) => {
        setShowModalSheet(true);
      },
    }
  );

  return (
    <div className="w-full mb-3">
      <AuthLayout
        title="Reset Password"
        body={
          <form action="" className="space-y-6" onSubmit={onSubmit}>
            <PhoneInput
              keyVal="signin-phone-input-key"
              label="Phone Number"
              name="mobile_number"
              type="number"
              textColor="white"
              inputClassName="text-white"
              phoneCode={getValues()?.phone_code || ''}
              register={register}
              setValue={setValue}
            />
            <div>
              <StatefulButton
                idleText="Reset"
                processingText="Resetting..."
                isFetching={passwordResetRequest.isFetching}
              />
            </div>
            <ModalSheet
              initialState={showModalSheet && passwordResetRequest.isSuccess}
              updateParentState={setShowModalSheet}
              Body={
                <div className="px-8 justify-center items-center">
                  <div className="text-center my-10">
                    Your Password has been reset{'\n'} Login to continue with
                    your new password
                  </div>
                  <Button
                    label="Login"
                    className="flex-1"
                    bgColor="[#0044AA]"
                    textColor="white"
                    onClick={() => {
                      navigate('/login', {
                        replace: true,
                      });
                    }}
                  />
                </div>
              }
              HeaderCenterText={'Reset Successful'}
              HeaderRightOnClick={() => {}}
              HeaderRightIcon={<></>}
              HeaderLeftOnClick={() => {}}
              HeaderLeftIcon={<></>}
            ></ModalSheet>
          </form>
        }
      />
    </div>
  );
}
