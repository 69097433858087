import { WithAccessTokenOnly } from './request-types/general';
import { PaymentSettings } from './response-types/settingsRes';
import axios from 'axios';

export async function getPaymentSettings({
  accessToken,
}: WithAccessTokenOnly): Promise<PaymentSettings> {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v2/app/settings`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  }).then(function (response: any) {
    return response.data;
  });
}
