import React from "react";

export function FieldCard({ FieldContent }: { FieldContent: JSX.Element }) {
    return (
        <div className="p-4 mb-3 flex items-center bg-white shadow rounded-lg cursor-pointer m-5">
            <div className="w-full flex items-center justify-between">
                {FieldContent}
            </div>
        </div>
    );
}
