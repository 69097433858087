import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BackArrowButton } from 'src/components/BackArrowButton';
import { JustifyBetween } from 'src/components/JustifyBetween';
import { LayoutWithoutBottonBtnAndNav } from 'src/layouts/LayoutWithoutBottonBtnAndNav';
import { UserTransaction } from 'src/services/response-types/userTransactionsRes';
import { separateNumberWithCommas } from 'src/utils/helpers';

export function TransactionDetail() {
  let { state }: { state: any } = useLocation();
  let txn: UserTransaction = state;

  const keyExtracts = [
    {
      key: 'reference_number',
      title: 'Ref',
    },
    {
      key: 'recipient.first_name',
      title: 'Recipient First Name',
    },
    {
      key: 'recipient.last_name',
      title: 'Recipient Last Name',
    },
    {
      key: 'recipient.mobile_number',
      title: 'Recipient Mobile Number',
      prefix: 'recipient.phone_code',
    },
    {
      title: 'Payment Method',
      key: 'payment_method.name',
    },
    {
      key: 'amount',
      title: 'Amount',
      prefix: 'currency.symbol',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
    {
      key: 'service_charge',
      title: 'Charge',
      prefix: 'currency.symbol',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
    {
      key: 'total_amount',
      title: 'Transaction Amount',
      prefix: 'currency.symbol',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
    {
      key: 'created_at',
      title: 'Date',
      manipulator: (value: string) => {
        return moment(value).calendar();
      },
    },
    {
      key: 'transaction_status_title',
      title: 'Status',
    },
    {
      title: 'Token',
      key: 'meta.token',
    },
    {
      title: 'Units',
      key: 'meta.units',
    },
  ];

  return (
    <LayoutWithoutBottonBtnAndNav
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Transaction Detail
          </p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-full">
              <div className="flex items-center  justify-between shadow-lg m-4 rounded-lg">
                <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                  {keyExtracts.map(function (item, index) {
                    if (_.get(txn, item.key)) {
                      return (
                        <div key={index} className="p-4 w-[100%] border-b-2">
                          <JustifyBetween
                            Left={
                              <p className="text-[#0044AA] font-normal text-base">
                                {item.title}
                              </p>
                            }
                            Right={
                              <p className="text-[#0044AA] font-normal text-base">
                                {item.prefix && _.get(txn, item.prefix, 'N/A')}{' '}
                                {item.manipulator
                                  ? item.manipulator(
                                      _.get(txn, item.key, 'N/A')
                                    )
                                  : _.get(txn, item.key, 'N/A')}
                              </p>
                            }
                          />
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}
