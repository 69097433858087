import React from 'react';
import { useQuery } from 'react-query';
import { loadInfiniteTransactionsAction } from '../../src/services/transactions';
import { useStore } from '../../src/store/store';

import { AppBarAndMiddleContent } from '../../src/layouts/LayoutWithBottomNav';
import FlexibleMiddleContent from '../../src/layouts/FlexibleMiddleContent';
import TransactionItem from '../../src/components/TransactionItem';
import { TopMarginedSpinner } from 'src/components/LoadingSpinner';

export function TransactionHistory() {
  const accessToken = useStore((state) => state.token?.access_token!);

  const { data, refetch, isFetching } = useQuery(
    'loadInfiniteTransactionsAction',
    async () => loadInfiniteTransactionsAction({ accessToken }),
    {
      enabled: true,
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  return (
    <AppBarAndMiddleContent
      AppBarContent={
        <div className="flex p-5 items-center justify-between">
          <p className="text-white font-semibold text-2xl">
            Transaction History
          </p>
        </div>
      }
      MiddleContent={
        <FlexibleMiddleContent
          SubHeaderContent={
            <div className="flex justify-between px-5 pb-3 border-b-2">
              <p className="text-[#0044AA] text-lg font-semibold">
                Transactions
              </p>
              <p
                className="text-[#0044AA] text-base text-opacity-50"
                onClick={() => {
                  refetch({ throwOnError: true });
                }}
              >
                Refresh
              </p>
            </div>
          }
          BodyContent={
            <div className="  ">
              <ul className="w-full px-[20px] pt-3">
                {isFetching && <TopMarginedSpinner />}
                {(data?.data || []).map((txn, index: number) => (
                  <li
                    key={index}
                    className="p-4 mb-3 flex items-center bg-white shadow rounded-lg cursor-pointer"
                  >
                    <TransactionItem txn={txn} />
                  </li>
                ))}
              </ul>
            </div>
          }
        />
      }
    />
  );
}
