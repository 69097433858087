import moment from "moment";
import React from "react";
import { UserTransaction } from "../../src/services/response-types/userTransactionsRes";
import { separateNumberWithCommas } from "../../src/utils/helpers";

export default function TransactionItem({ txn }: { txn: UserTransaction }) {
    return (
        <div className="w-full flex items-center justify-between">
            <div className="flex items-center ">
                <div>
                    <p className="ml-2 text-gray-700 font-semibold font-sans tracking-wide">
                        {txn.transaction_type.code == "topup"
                            ? "Topup"
                            : `${txn.recipient?.first_name} ${txn.recipient?.last_name}`}
                    </p>
                    <p className="ml-2 text-gray-400 font-normal font-sans tracking-wide">
                        {moment(txn.created_at.toLocaleString()).calendar()}
                    </p>
                </div>
            </div>
            <div className="flex-col text-right">
                <p className="text-grey text-sm text-opacity-50">
                    {txn.transaction_type.code == "topup"
                        ? "Wallet Top up"
                        : txn.payment_method?.name}{" "}
                    - {txn.transaction_status_title}
                </p>
                <p className="text-[#0044AA] font-semibold text-xl">
                    {`${txn.currency.symbol}
                      ${separateNumberWithCommas(txn.amount)}`}
                </p>
            </div>
        </div>
    );
}
