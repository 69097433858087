import { Token, User } from '../../src/types/account';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import { EnterAmountParams } from 'src/pages/EnterAmount';
import { PaymentSettings } from 'src/services/response-types/settingsRes';

export type TransactionDataType = Partial<EnterAmountParams>;

interface AppState {
  token: Token | null;
  setToken: (val: Token | null) => void;

  user: User | null;
  setUser: (val: User | null) => void;

  countries: any[] | null;
  setCountries: (val: User | null) => void;

  paymentSettings: PaymentSettings | undefined;
  setPaymentSettings: (val: PaymentSettings) => void;

  transactionData: TransactionDataType | undefined;
  setTransactionData: (val: TransactionDataType) => void;
}

let store = (set: SetState<AppState>) => ({
  token: null,
  setToken: (val: any) => {
    set((_state: AppState) => ({
      token: val,
    }));
  },

  user: null,
  setUser: (val: any) => {
    set((_state: AppState) => ({
      user: val,
    }));
  },

  countries: null,
  setCountries: (val: any) => {
    set((_state: AppState) => ({
      countries: val,
    }));
  },

  transactionData: undefined,
  setTransactionData: (val: TransactionDataType) => {
    set((_state: AppState) => ({
      transactionData: val,
    }));
  },

  paymentSettings: undefined,
  setPaymentSettings: (val: PaymentSettings) => {
    set((_state: AppState) => ({
      paymentSettings: val,
    }));
  },
});

// @ts-ignore
store = persist(store, { name: 'cx-store' });

export const useStore = create<AppState>(store);
