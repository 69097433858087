import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RecipientTransaction } from 'src/services/response-types/recipientTransactions';
import { UserTransaction } from '../../src/services/response-types/userTransactionsRes';
import { separateNumberWithCommas } from '../../src/utils/helpers';

export type inputType = 'text' | 'password' | 'number';

export function TransactionComponent({
  txn,
}: {
  txn: UserTransaction | RecipientTransaction;
}) {
  const navigate = useNavigate();
  return (
    <li
      className="p-4 mb-3 flex items-center bg-white shadow rounded-lg cursor-pointer"
      onClick={() => {
        navigate('/customer/transaction-detail', {
          state: txn as UserTransaction,
        });
      }}
    >
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center ">
          {/* <img
                                                className="w-10 h-10 rounded-lg"
                                                src={user.avatar}
                                                alt={user.name}
                                            /> */}
          <div>
            <p className="ml-2 text-gray-700 font-semibold font-sans tracking-wide">
              {txn.transaction_type.code == 'topup'
                ? 'Topup'
                : `${txn.recipient?.first_name} ${txn.recipient?.last_name}`}
            </p>
            <p className="ml-2 text-gray-400 font-normal font-sans tracking-wide">
              {moment(txn.created_at.toLocaleString()).calendar()}
            </p>
          </div>
        </div>
        <div className="flex-col text-right">
          <p className="text-grey text-sm text-opacity-50">
            {txn.transaction_type.code == 'topup'
              ? 'Wallet Top up'
              : txn.payment_method?.code}{' '}
            - {txn.transaction_status_title}
          </p>
          <p className="text-[#0044AA] font-semibold text-xl">
            {`${txn.currency.symbol}
                            ${separateNumberWithCommas(txn.amount)}`}
          </p>
        </div>
      </div>
    </li>
  );
}
