import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'src/store/store';

import { JustifyBetween } from '../components/JustifyBetween';
import { AppBarAndMiddleContent } from '../layouts/LayoutWithBottomNav';

export function Account() {
  const navigation = useNavigate();
  const { setToken, setUser, user } = useStore((state) => {
    return {
      setUser: state.setUser,
      setToken: state.setToken,
      user: state.user,
    };
  });
  let navigate = useNavigate();
  let settingsOptions = [
    {
      title: 'Profile',
      destinationPage: '/customer/account/profile',
      svg: '',
    },
    {
      title: 'Settings',
      destinationPage: '/customer/account/settings',
      svg: '',
    },
    {
      title: 'About',
      destinationPage: '/customer/account/about',
      svg: '',
    },
    {
      title: 'Logout',
      destinationPage: '',
      svg: '',
      actions: () => {
        setUser(null);
        setToken(null);
        navigation('/login', { replace: true });
      },
    },
  ];

  return (
    <AppBarAndMiddleContent
      AppBarContent={
        <div className="my-4">
          <p className="text-white font-semibold text-2xl">
            {user?.first_name} {user?.last_name}
          </p>
          <p className="text-white text-base text-opacity-50">{`CX-${user?.id}`}</p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-full">
              <div className="flex items-center  justify-between shadow-lg m-4 rounded-lg">
                <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                  {settingsOptions.map((settingsOption, index) => (
                    <div
                      key={index}
                      className="p-4 w-[100%] flex border-b-2 cursor-pointer"
                      onClick={() => {
                        if (settingsOption.actions) {
                          settingsOption.actions();
                        } else {
                          navigate(settingsOption.destinationPage);
                        }
                      }}
                    >
                      <JustifyBetween
                        Left={
                          <p className="text-[#0044AA] font-semibold text-base">
                            {settingsOption.title}
                          </p>
                        }
                        Right={
                          settingsOption.svg ? (
                            <p className="text-[#0044AA] font-normal text-base">
                              {settingsOption.svg}
                            </p>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}
