import React from "react";

export function AuthLayout({
    title,
    subtitle,
    body,
}: {
    title?: string;
    subtitle?: string;
    body: JSX.Element;
}) {
    return (
        <div className="min-h-screen h-14 bg-gradient-to-b from-[#0044AA] to-[#001F4F] flex flex-col justify-center">
            <div className="max-w-md w-full mx-auto">
                <div className="text-3xl font-bold text-white  mt-2 text-center">
                    {title}
                </div>
                <div className="text-center font-normal text-white mt-6">
                    {subtitle}
                </div>
            </div>
            <div className="max-w-md w-full mx-auto mt-4 p-8">{body}</div>
        </div>
    );
}
