import React from "react";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import BackArrowIcon from "../assets/svgs/back_arrow.js";

export function BackArrowButton() {
    let navigate = useNavigate();
    return (
        <div
            className="h-8 w-10 flex items-center justify-center rounded"
            onClick={() => {
                navigate(-1);
            }}
        >
            <BackArrowIcon />
        </div>
    );
}
