import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { loadCountriesFromAPI } from '../../src/services/countries';
import ModalSheet from '../../src/components/BottomSheet';
import { CountryFlagJustifyBetween } from '../../src/components/CountryFlagJustifyBetween';

// @ts-ignore
import RefreshIconSvg from '../assets/svgs/refresh_icon.js';
// @ts-ignore
import CloseIconSvg from '../assets/svgs/close.js';
import { useStore } from 'src/store/store';
import { SpinnerRow } from './LoadingSpinner';
import { Flag } from './CurrencySymbolFlag';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

export type inputType = 'text' | 'password' | 'number';

export function PhoneInput<T extends FieldValues>({
  register,
  setValue,
  keyVal,
  name,
  label,
  type,
  textColor,
  defaultPhoneCode,
  phoneCode,
  phoneCodeFixed = false,
  inputClassName,
  mobileNumber,
  defaultCountryCode,
  setShowParentModalSheet,
}: {
  register: any;
  setValue: UseFormSetValue<T>;
  phoneCodeFixed?: boolean;
  name: string;
  keyVal?: string;
  label?: string;
  type: inputType;
  textColor?: string;
  phoneCode?: string;
  mobileNumber?: string;
  defaultPhoneCode?: string;
  defaultCountryCode?: string;
  inputClassName?: string;
  setShowParentModalSheet?: (val: boolean) => void;
}) {
  const [showModalSheet, setShowModalSheet] = useState(false);
  const setCountries = useStore((state) => state.setCountries);
  const [country, setCountry] = useState(undefined);

  const { data, isFetching } = useQuery(
    'loadCountries',
    // @ts-ignore
    async () => loadCountriesFromAPI({}),
    {
      enabled: true,
      onSuccess(data) {
        setCountries(data);
      },
    }
  );

  console.log('country:', country);

  return (
    <div className="w-full mb-3">
      {label && (
        <label
          htmlFor=""
          className={`text-sm  mb-4  font-bold text-${
            textColor || 'white'
          } block`}
        >
          {label}
        </label>
      )}
      <div
        className={`flex flex-row rounded ${
          textColor != 'white' ? 'border-2 border-gray-300' : 'border-0'
        }`}
      >
        <div
          onClick={() => {
            setShowModalSheet(true);
            if (setShowParentModalSheet) {
              setShowParentModalSheet(false);
            }
          }}
          className={`w-${
            !(phoneCode || defaultPhoneCode)
              ? '[100%] rounded-tr rounded-br'
              : '[100px]'
          }  border-0 bg-opacity-20 bg-white h-[40px] flex items-center justify-center text-center rounded-tl rounded-bl`}
        >
          <div
            className={`h-100 w-[100px] flex items-center text-${
              textColor || 'white'
            }`}
          >
            {(country != undefined || defaultCountryCode) && (
              <div className="mr-4">
                {/* @ts-ignore */}
                <Flag countryCode={country?.code ?? defaultCountryCode} />
              </div>
            )}{' '}
            {defaultPhoneCode || phoneCode}
          </div>
        </div>
        {(phoneCode || defaultPhoneCode) && (
          <input
            {...register(name)}
            name={name}
            type={type}
            value={mobileNumber}
            className={`flex-1 p-2rounded-tr rounded-br border-0 bg-opacity-20 bg-white ${inputClassName}`}
          />
        )}
      </div>
      <ModalSheet
        key={keyVal}
        initialState={phoneCodeFixed ? false : showModalSheet}
        updateParentState={setShowModalSheet}
        Body={
          <div className="px-8">
            {isFetching && <SpinnerRow description="Loading..." />}
            {(data || []).map((country: any, index: number) => {
              function onCountryChange(country: any) {
                setTimeout(() => {
                  setShowModalSheet(false);
                  setCountry({ ...country });

                  // @ts-ignore
                  setValue('phone_code', country.phone_code);
                }, 200);
              }
              return (
                <div
                  key={index}
                  onClick={() => {
                    onCountryChange && onCountryChange({ ...country });
                  }}
                >
                  <CountryFlagJustifyBetween
                    onCountryChange={(country) => {
                      onCountryChange(country);
                    }}
                    country={country}
                    register={register}
                  />
                  <div className="pb-4"></div>
                </div>
              );
            })}
          </div>
        }
        HeaderCenterText={'Select Country'}
        HeaderRightOnClick={() => {}}
        HeaderRightIcon={<RefreshIconSvg color="#0044AA" />}
        HeaderLeftOnClick={() => {
          if (setShowParentModalSheet) setShowParentModalSheet(true);
        }}
        HeaderLeftIcon={<CloseIconSvg color="#0044AA" />}
      ></ModalSheet>
    </div>
  );
}
