import { useEffect, useState } from "react";
import {
    PaymentMethod,
    PaymentMethodCategoryCode,
    PaymentSettings,
    SourceCountryOrDestinationCountryCodeType,
} from "../../src/services/response-types/settingsRes";
import * as _ from "lodash";
import { ValueOf } from "../../src/types/general";

export interface PaymentMethodInputMap {
    baseCountryCode: SourceCountryOrDestinationCountryCodeType;
    receiverCountryCode: SourceCountryOrDestinationCountryCodeType;
    paymentSettings: PaymentSettings;
}

export default function usePaymentMethods({
    baseCountryCode,
    receiverCountryCode,
    paymentSettings,
}: PaymentMethodInputMap) {
    const [utilities, setUtilities] = useState<PaymentMethod[]>([]);
    const [remittanceOptions, setRemittanceOptions] = useState<PaymentMethod[]>(
        []
    );

    const getCategoryPaymentMethods = (
        paymentSettings: PaymentSettings,
        payment_method_category_code: ValueOf<PaymentMethodCategoryCode>
    ) => {
        return _.uniqBy(
            paymentSettings.payment_routes
                .filter(
                    (paymentRoute) =>
                        paymentRoute.source_country.code == baseCountryCode &&
                        paymentRoute.destination_country.code ==
                            receiverCountryCode &&
                        paymentRoute.status == 1 &&
                        paymentRoute.payment_method
                            .payment_method_category_code ==
                            payment_method_category_code
                )
                .map((paymentRoute) => paymentRoute.payment_method),
            "id"
        );
    };

    useEffect(() => {
        setUtilities(getCategoryPaymentMethods(paymentSettings, "utility"));
        setRemittanceOptions(
            getCategoryPaymentMethods(paymentSettings, "sendMoney")
        );
    }, []);

    return { utilities, remittanceOptions };
}
