import React from 'react';
import { Flag } from './CurrencySymbolFlag';
import { JustifyBetween } from './JustifyBetween';

export function CountryFlagJustifyBetween({
  country,
  register,
  onCountryChange,
}: {
  onCountryChange: (country: any) => void;
  country?: any;
  register: any;
}) {
  return (
    country && (
      <JustifyBetween
        Left={
          <div className="flex items-center">
            <div className="-ml-4 mr-4">
              <Flag countryCode={country.code} />
            </div>
            <div>
              <p className="text-[#0044AA] font-semibold text-lg">
                {country.name}
              </p>
              <p className="text-gray-400 font-normal text-base mt-1">
                {country.currency_code}
              </p>
            </div>
          </div>
        }
        Right={
          <>
            <input
              onClick={() => {
                onCountryChange && onCountryChange({ ...country });
              }}
              className="form-check-input appearance-none rounded-full h-4 w-4 border-2 border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              {...register('phone_code', {
                onChange: (e: any) => {
                  console.log('e:', e);
                  console.log('country-:', country);
                },
              })}
              name={'phone_code'}
              value={country.phone_code}
              id="flexRadioDefault10"
            />
          </>
        }
      />
    )
  );
}
