import React from 'react';

// @ts-ignore
import BackArrowIcon from '../assets/svgs/back_arrow.js';
import { AppBarAndMiddleContent } from '../../src/layouts/LayoutWithBottomNav';
import FlexibleMiddleContent from '../../src/layouts/FlexibleMiddleContent';
import useGetRecipients from '../../src/hooks/useRecipients';
import { useStore } from '../../src/store/store';
import { useNavigate } from 'react-router-dom';
import { Recipient } from '../../src/services/response-types/recipientsRes';
import { TopMarginedSpinner } from 'src/components/LoadingSpinner';

export function ReceipientsPage() {
  const accessToken = useStore((state) => state.token?.access_token);
  const navigate = useNavigate();
  const { getRecipientsRequest } = useGetRecipients({
    accessToken: accessToken!,
  });

  return (
    <AppBarAndMiddleContent
      AppBarContent={
        <div className="flex p-5 items-center justify-between">
          <p className="text-white font-semibold text-2xl">Recipients</p>
          {/* <div className="flex">
                        <BackArrowIcon />
                        <div className="mr-10"></div>
                        <BackArrowIcon />
                    </div> */}
        </div>
      }
      MiddleContent={
        <FlexibleMiddleContent
          BodyContent={
            <ul className="w-full px-[20px] pt-3">
              {(getRecipientsRequest.data?.data || []).map((user, index) => (
                <li
                  onClick={() => {
                    navigate('/customer/recipients/details', {
                      state: user as Recipient,
                    });
                  }}
                  key={index}
                  className="p-4 mb-3 flex items-center bg-white shadow rounded-lg cursor-pointer"
                >
                  <div className="w-full flex items-center justify-between">
                    <div className="flex items-center ">
                      {/* <div className="w-10 h-10 rounded-lg bg-slate-400" /> */}
                      <div>
                        <p className="ml-2 text-gray-700 font-semibold font-sans tracking-wide">
                          {user.first_name} {user.last_name}
                        </p>
                        <p className="ml-2 text-gray-400 font-normal font-sans tracking-wide">
                          +{user.phone_code} {user.mobile_number}
                        </p>
                      </div>
                    </div>
                    <div className="flex-col text-right">
                      <BackArrowIcon />
                    </div>
                  </div>
                </li>
              ))}
              {getRecipientsRequest.isFetching && <TopMarginedSpinner />}
            </ul>
          }
        />
      }
    />
  );
}
