import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { loadInfiniteTransactionsAction } from '../../src/services/transactions';
import { useStore } from '../../src/store/store';

// @ts-ignore
import CoinPileSvg from '../assets/svgs/coin_pile.js';
// @ts-ignore
import TransactSvg from '../assets/svgs/transaction.js';
// @ts-ignore
import UserIcon from '../assets/svgs/user_icon.js';
// @ts-ignore
import UtilityIcon from '../assets/svgs/utility_icon.js';
// @ts-ignore
import HistoryIcon from '../assets/svgs/history_icon.js';
// @ts-ignore
import HomeIcon from '../assets/svgs/home_icon.js';
// @ts-ignore
import RecepientIcon from '../assets/svgs/recipient_icon.js';
// @ts-ignore
import RefreshIconSvg from '../assets/svgs/refresh_icon.js';
// @ts-ignore
import CloseIconSvg from '../assets/svgs/close.js';
// @ts-ignore
import CalculateIconSvg from '../assets/svgs/calculate_icon.js';

import { AppBarAndMiddleContent } from '../../src/layouts/LayoutWithBottomNav';
import { useNavigate } from 'react-router-dom';
import FlexibleMiddleContent from '../../src/layouts/FlexibleMiddleContent';
import { BalanceBar } from '../../src/components/BalanceBar';
import { TransactionComponent } from 'src/components/TransactionComponent';
import ModalSheet from 'src/components/BottomSheet';
import { JustifyBetween } from 'src/components/JustifyBetween';
import { SelectedTxnOption } from './TransactionOption';
import { TopMarginedSpinner } from 'src/components/LoadingSpinner';

export function Home() {
  let navigate = useNavigate();
  const [showModalSheet, setShowModalSheet] = useState(false);
  const { accessToken } = useStore((state) => {
    return {
      accessToken: state.token?.access_token || '',
    };
  });

  const { data, refetch, isFetching } = useQuery(
    'loadInfiniteTransactionsAction',
    async () => loadInfiniteTransactionsAction({ accessToken }),
    {
      enabled: true,
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const quickTaps = [
    {
      svg: <CoinPileSvg fill="#0044AA" />,
      label: 'Top Up',
      onTap: () => {
        setShowModalSheet(true);
      },
    },
    {
      svg: <TransactSvg fill="#0044AA" />,
      label: 'Send',
      onTap: () => {
        navigate('/customer/transact/step-1/select-country', {
          state: {
            transferOptionsCategory: 'sendMoney' as SelectedTxnOption['code'],
          },
        });
      },
    },
    {
      svg: <UtilityIcon color="#0044AA" />,
      label: 'Utilities',
      onTap: () => {
        navigate('/customer/transact/step-1/select-country', {
          state: {
            transferOptionsCategory: 'utility' as SelectedTxnOption['code'],
            isCalculateOption: false,
          },
        });
      },
    },
    {
      svg: <CalculateIconSvg color="#0044AA" />,
      label: 'Calculate',
      onTap: () => {
        navigate('/customer/transact/step-1/select-country', {
          state: {
            transferOptionsCategory: 'sendMoney' as SelectedTxnOption['code'],
            isCalculateOption: true,
          },
        });
      },
    },
  ];

  return (
    <AppBarAndMiddleContent
      AppBarContent={<BalanceBar />}
      MiddleContent={
        <FlexibleMiddleContent
          TopContent={
            <div className="flex justify-around">
              {quickTaps.map((quickTap, index) => (
                <div
                  onClick={quickTap.onTap ? quickTap.onTap : () => {}}
                  key={index}
                  className="w-[20%] focus:text-[#0044AA] hover:text-[#0044AA] rounded-lg shadow-md aspect-square border-2 flex items-center justify-center flex-col"
                >
                  <div className="h-[60%] w-[60%] flex justify-center items-center">
                    {quickTap.svg}
                  </div>
                  {/* <CoinPileSvg fill="#000000" /> */}
                  <p>{quickTap.label}</p>
                </div>
              ))}
            </div>
          }
          SubHeaderContent={
            <div className="flex justify-between px-5 pb-3 border-b-2">
              <p className="text-[#0044AA] text-lg font-semibold">
                Transactions
              </p>
              <p
                className="text-[#0044AA] text-base text-opacity-50 cursor-pointer"
                onClick={() => {
                  refetch({ throwOnError: true });
                }}
              >
                Refresh
              </p>
            </div>
          }
          BodyContent={
            <div className="  ">
              <ModalSheet
                initialState={showModalSheet}
                updateParentState={setShowModalSheet}
                Body={
                  <div className="p-8 ">
                    {['Mobile Money', 'Card'].map(
                      (topUpMethod: any, index: number) => (
                        <div
                          key={index}
                          className="justify-center inline-block w-[100%] items-center border-gray-200 px-10 py-4 mb-4 border-2 rounded-md"
                        >
                          <JustifyBetween
                            onClick={() => {
                              if (index == 0) {
                                navigate('/customer/topup/mm');
                              } else {
                                navigate('/customer/topup/card');
                              }
                            }}
                            Left={
                              <div className="flex items-center">
                                <div>
                                  <p className="text-[#0044AA] font-semibold text-lg">
                                    {topUpMethod}
                                  </p>
                                </div>
                              </div>
                            }
                            Right={<></>}
                          />
                        </div>
                      )
                    )}
                  </div>
                }
                HeaderCenterText={'Select Topup Option'}
                HeaderRightOnClick={() => {}}
                HeaderRightIcon={<RefreshIconSvg color="#0044AA" />}
                HeaderLeftOnClick={() => {}}
                HeaderLeftIcon={<CloseIconSvg color="#0044AA" />}
              ></ModalSheet>
              <ul className="w-full px-[20px] pt-3">
                {isFetching && <TopMarginedSpinner />}
                {(data?.data || []).map((txn, index: number) => (
                  <TransactionComponent key={index} txn={txn} />
                ))}
              </ul>
            </div>
          }
        />
      }
    />
  );
}
