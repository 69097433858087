import React from 'react';
import { CurrencySymbolFlag } from '../../src/components/CurrencySymbolFlag';
import { inputType, TextInput } from './TextInput';

export function AmountFlag({
  register,
  label,
  name,
  type,
  control,
  currency,
  countryCode,
}: {
  register: any;
  label: string;
  name: string;
  type: inputType;
  control?: any;
  currency?: string;
  countryCode: string;
}) {
  return (
    <div className="flex flex-row items-center mr-4">
      <TextInput
        label={label}
        name={name}
        type={type}
        control={control}
        textColor="black"
        register={register}
      />

      <div className="self-end mb-5">
        <CurrencySymbolFlag country={{ countryCode, currency }} />
      </div>
    </div>
  );
}
