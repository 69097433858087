import React from 'react';

// @ts-ignore
import CoinPileSvg from '../assets/svgs/coin_pile.js';
// @ts-ignore
import TransactSvg from '../assets/svgs/transaction.js';
// @ts-ignore
import UserIcon from '../assets/svgs/user_icon.js';
// @ts-ignore
import UtilityIcon from '../assets/svgs/utility_icon.js';
// @ts-ignore
import HistoryIcon from '../assets/svgs/history_icon.js';
// @ts-ignore
import HomeIcon from '../assets/svgs/home_icon.js';
// @ts-ignore
import RecepientIcon from '../assets/svgs/recipient_icon';

import { LayoutWithoutBottonBtnAndNav } from '../../src/layouts/LayoutWithoutBottonBtnAndNav';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Recipient } from 'src/services/response-types/recipientsRes';
import { useQuery } from 'react-query';
import { loadRecepientTransactions } from '../../src/services/transactions';
import { useStore } from '../../src/store/store';
import { TransactionComponent } from 'src/components/TransactionComponent';
import { RecipientTransaction } from 'src/services/response-types/recipientTransactions';
import { RecepientSourceParams } from './RecepientSource';
import { getPaymentSettings } from 'src/services/paymentSettings';

export function RecepientDetailPage() {
  const token = useStore((state) => state.token);
  const user = useStore((state) => state.user);
  let { state }: { state: any } = useLocation();
  let navigate = useNavigate();
  let paramsData: Recipient = state;

  const { data } = useQuery(
    'loadRecepientTransactions',
    async () =>
      await loadRecepientTransactions({
        accessToken: token!.access_token!,
        recipient: paramsData,
      }),
    {
      enabled: true,
    }
  );

  const getPaymentSettingsRequest = useQuery(
    'getPaymentSettings',
    () =>
      getPaymentSettings({
        accessToken: token!.access_token!,
      }),
    {
      enabled: true,
    }
  );

  return (
    <LayoutWithoutBottonBtnAndNav
      AppBarContent={
        <div className="py-4">
          <BackArrowButton />
          <div className="flex flex-col items-center">
            <p className="text-white font-semibold text-2xl">
              {paramsData.first_name} {paramsData.last_name}
            </p>
            <p className="text-white text-base text-opacity-50">
              {paramsData.country.name}
            </p>
            <p className="text-white font-normal text-xl">
              +{paramsData.phone_code} {paramsData.mobile_number}
            </p>
          </div>
        </div>
      }
      MiddleContent={
        <>
          <section className="w-full bg-white mt-4">
            <div className="flex justify-between px-5 pb-3">
              <p className="text-[#0044AA] text-lg font-semibold">
                Payment methods
              </p>
            </div>
            <div
              className={`flex justify-${
                // paramsData.payment_methods.length >= 4
                false ? 'around' : 'start'
              } flex-wrap mx-5 gap-2`}
            >
              {[...paramsData.payment_methods].map((payment_method, index) => (
                <div
                  onClick={() => {
                    let paymentMethodKeys = Object.keys(payment_method.meta);
                    let paymentMethodMeta: any;

                    if (paymentMethodKeys?.length > 0) {
                      paymentMethodMeta =
                        // @ts-ignore
                        payment_method.meta[paymentMethodKeys[0]];
                    }

                    navigate('/customer/transact/step-4/enter-amount', {
                      state: {
                        mobile_number: user?.mobile_number,
                        payment_method_code: payment_method.code,
                        payment_method_id: payment_method.id,
                        customer_name: `${user?.first_name} ${user?.last_name}`,
                        phone_code: Number(paramsData.phone_code),
                        recipient_id: `${paramsData.id}`,
                        sending_to: paramsData.country_code,
                        sending_to_country_code: paramsData.country_code,
                        sending_to_currency: paramsData.country.currency_code,
                        paymentSettings: getPaymentSettingsRequest.data,
                        sending_from_currency: user?.country.currency_code,
                        sending_from_country_code: user?.country.code,
                        payment_method_category_code: payment_method?.code,
                        payment_method_name: payment_method?.name,
                        account_no: paymentMethodMeta?.account_no,
                        area: paymentMethodMeta?.area,
                        transferOptionsCategory:
                          payment_method.payment_method_category_code,
                      } as RecepientSourceParams,
                    });
                  }}
                  key={index}
                  className="w-[23%] focus:text-[#0044AA] hover:text-[#0044AA] rounded-lg shadow-md aspect-square border-2 flex items-center justify-center flex-col"
                >
                  <div className="h-[60%] w-[60%] flex justify-center items-center">
                    <TransactSvg fill="#0044AA" />
                  </div>
                  {/* <CoinPileSvg fill="#000000" /> */}
                  <p className="text-center whitespace-nowrap overflow-hidden w-[60%]">
                    {payment_method.name}
                  </p>
                </div>
              ))}
            </div>
          </section>
          <section className=" bg-black">
            <div className="h-[100%] w-[100%] bg-white mt-3 border-b-4">
              <div className="flex justify-between px-5 pb-3 border-b-2">
                <p className="text-[#0044AA] text-lg font-semibold">
                  Recent Transactions
                </p>
                <p className="text-[#0044AA] text-base text-opacity-50">
                  Refresh
                </p>
              </div>
              <div>
                <div>
                  <ul className="w-full px-[20px] pt-3">
                    {(data || []).map(
                      (txn: RecipientTransaction, index: number) => (
                        <TransactionComponent key={index} txn={txn} />
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}
