import React from 'react';

export function SelectInput({
  register,
  name,
  label,
  options,
  textColor,
  inputClassName,
}: {
  label: string;
  register: any;
  inputClassName?: string;
  name: string;
  textColor: string;
  options: { name: string; value: string }[];
}) {
  return (
    <div className="w-full mb-3">
      <label htmlFor="" className="text-sm font-bold text-black block">
        {label}
      </label>
      {/* <input
                {...register(name)}
                name={name}
                type="text"
                className="w-full p-2 rounded mt-1 border-0  text-white bg-opacity-20 bg-white"
            /> */}
      <select
        name="source"
        id=""
        className={`w-full mt-4 p-2 rounded bg-opacity-20 bg-white ${inputClassName}  ${
          textColor != 'white' ? 'border-2 border-gray-300' : 'border-0'
        }`}
        {...register(name)}
      >
        {options.map((option) => {
          return <option value={option.value}>{option.name}</option>;
        })}
      </select>
    </div>
  );
}
