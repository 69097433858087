export default ({ fill }) => (<svg width="51" height="51" viewBox="0 0 51 51" fill={fill} xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path d="M28.1671 20.5909C27.9011 20.6037 27.651 20.7217 27.472 20.9189C27.293 21.116 27.1997 21.3762 27.2125 21.6422C27.2253 21.9082 27.3433 22.1582 27.5404 22.3372C27.7376 22.5163 27.9978 22.6096 28.2638 22.5968L33.5465 22.333L21.614 35.6623C21.4372 35.8599 21.346 36.1197 21.3606 36.3845C21.3753 36.6493 21.4945 36.8974 21.6921 37.0743C21.8897 37.2512 22.1495 37.3424 22.4143 37.3278C22.6791 37.3131 22.9273 37.1939 23.1042 36.9963L35.0366 23.667L35.3122 28.9065C35.3199 29.0377 35.3534 29.166 35.4107 29.2843C35.468 29.4025 35.5481 29.5083 35.6463 29.5956C35.7445 29.6829 35.8589 29.75 35.9831 29.793C36.1072 29.8361 36.2386 29.8543 36.3698 29.8466C36.5009 29.8388 36.6293 29.8054 36.7475 29.748C36.8658 29.6907 36.9715 29.6107 37.0588 29.5125C37.1461 29.4142 37.2132 29.2998 37.2563 29.1757C37.2994 29.0515 37.3175 28.9201 37.3098 28.789L36.8328 20.1613L28.1671 20.5909Z" />
    <path d="M22.5775 29.2636C22.7108 29.1164 22.7973 28.9328 22.8259 28.7363C22.8545 28.5397 22.8239 28.3391 22.738 28.16C22.6521 27.9809 22.5148 27.8314 22.3437 27.7306C22.1725 27.6298 21.9752 27.5823 21.777 27.5941L16.5159 27.8638L28.4416 14.5419C28.6185 14.3443 28.7097 14.0845 28.695 13.8197C28.6804 13.5549 28.5611 13.3068 28.3635 13.1299C28.1659 12.953 27.9062 12.8618 27.6413 12.8765C27.3765 12.8911 27.1284 13.0104 26.9515 13.208L15.0257 26.5298L14.7212 21.2777C14.7134 21.1465 14.68 21.0182 14.6226 20.8999C14.5653 20.7817 14.4853 20.6759 14.387 20.5886C14.2888 20.5014 14.1744 20.4343 14.0503 20.3912C13.9261 20.3481 13.7947 20.3299 13.6636 20.3377C13.5324 20.3454 13.404 20.3788 13.2858 20.4362C13.1676 20.4935 13.0618 20.5736 12.9745 20.6718C12.7982 20.8701 12.7079 21.1303 12.7235 21.3953L13.2229 30.0429L21.8729 29.5859C22.0059 29.5817 22.1367 29.5511 22.2577 29.4958C22.3786 29.4404 22.4874 29.3615 22.5775 29.2636Z" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="36" height="36" transform="translate(0 26.8224) rotate(-48.165)" />
    </clipPath>
  </defs>
</svg>)
