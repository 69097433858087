import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../components/TextInput';
import { AuthLayout } from '../../src/layouts/AuthLayout';
import { useQuery } from 'react-query';
import { loginUser } from '../../src/services/account';
import { LoginSuccessResponse } from '../../src/types/account';
import { useStore } from '../../src/store/store';
import { Link, useNavigate } from 'react-router-dom';

import { PhoneInput } from '../../src/components/PhoneInput';

// @ts-ignore
import RefreshIconSvg from '../assets/svgs/refresh_icon.js';
// @ts-ignore
import CloseIconSvg from '../assets/svgs/close.js';
import { StatefulButton } from 'src/components/Button';
import { loadTransactionSettings } from 'src/services/transactions';
import { PaymentSettings } from 'src/services/response-types/settingsRes';

interface FormData {
  mobile_number: string;
  password: string;
  phone_code: string;
}

export function LoginPage() {
  const { register, handleSubmit, getValues, setValue, control } =
    useForm<FormData>();
  const setPaymentSettings = useStore((state) => state.setPaymentSettings);
  const setToken = useStore((state) => state.setToken);
  const setUser = useStore((state) => state.setUser);
  let navigate = useNavigate();

  useWatch({ control, name: 'phone_code' });
  useWatch({ control, name: 'mobile_number' });

  const onSubmit = handleSubmit((_data: FormData) => {
    console.log(_data, loginUserRequest);
    loginUserRequest.refetch({ throwOnError: true });
  });

  const loginUserRequest = useQuery(
    'loginUser',
    async () => {
      return await loginUser({ data: getValues() });
      // return await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({});
      //   }, 5000);
      // });
    },
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (data: LoginSuccessResponse) => {
        setToken(data.token);
        setUser(data.user);

        if (data.user.mobile_verified_at) {
          navigate('/tabs');
        } else {
          navigate('/register/verify-phone');
        }
      },
    }
  );

  const loadTransactionSettingsRequest = useQuery(
    'loadTransactionSettings',
    async () => {
      return await loadTransactionSettings();
    },
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (data: PaymentSettings) => {
        setPaymentSettings(data);
      },
    }
  );

  useEffect(() => {
    loadTransactionSettingsRequest.refetch();
  }, []);

  return (
    <div className="w-full mb-3">
      <AuthLayout
        title="Welcome Back"
        subtitle="Sign in to continue"
        body={
          <form action="" className="space-y-6" onSubmit={onSubmit}>
            <PhoneInput<FormData>
              keyVal="signin-phone-input-key"
              label="Phone Number"
              name="mobile_number"
              type="number"
              textColor="white"
              inputClassName="text-white"
              phoneCode={getValues()?.phone_code || ''}
              register={register}
              setValue={setValue}
            />
            <TextInput
              label="Password"
              name="password"
              type="password"
              textColor="white"
              inputClassName="text-white"
              register={register}
            />
            <div className="border-gray-300 roudned mt-1">
              <div className="flex items-center justify-center">
                <div
                  className="font-medium text-sm text-white cursor-pointer"
                  onClick={() => {
                    navigate('/reset-password');
                  }}
                >
                  Forgot Password?
                </div>
              </div>
            </div>
            <div>
              <div className="w-full">
                <StatefulButton
                  idleText="Login"
                  processingText="Logging in..."
                  isFetching={loginUserRequest.isFetching}
                />
              </div>
            </div>
            <div className="border-gray-300 roudned mt-1">
              <div className="flex items-center justify-center">
                <div className="font-normal text-xs text-white">
                  Not Registered Yet?
                </div>
                <Link
                  to={'/register'}
                  className="font-normal text-xs text-[#3182CD] ml-4"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
}
