import React from 'react';

// @ts-ignore
import CoinPileSvg from '../assets/svgs/coin_pile.js';
// @ts-ignore
import TransactSvg from '../assets/svgs/transaction.js';
// @ts-ignore
import UserIcon from '../assets/svgs/user_icon.js';
// @ts-ignore
import UtilityIcon from '../assets/svgs/utility_icon.js';
// @ts-ignore
import HistoryIcon from '../assets/svgs/history_icon.js';
// @ts-ignore
import HomeIcon from '../assets/svgs/home_icon.js';
// @ts-ignore
import RecepientIcon from '../assets/svgs/recipient_icon.js';
import { AppBar } from '../../src/components/AppBarContent';

export function LayoutWithoutBottonBtnAndNav({
  AppBarContent,
  MiddleContent,
}: {
  AppBarContent: JSX.Element;
  MiddleContent: JSX.Element;
}) {
  return (
    <div className="h-[100vh] bg-gradient-to-b from-[#0044AA] to-[#001F4F] flex flex-col items-center">
      <div className="h-full bg-gradient-to-b flex flex-col items-center">
        <AppBar AppBarContent={AppBarContent} />
        <section className=" z-1 bg-white shadow flex-1 w-full">
          {MiddleContent}
        </section>
      </div>
    </div>
  );
}
