import React, { useEffect } from 'react';
import { AuthLayout } from '../../src/layouts/AuthLayout';
import { useQuery } from 'react-query';
import { resendOTP, verifyOTP } from '../../src/services/account';
import { LoginSuccessResponse } from '../../src/types/account';
import { useStore } from '../../src/store/store';
import { useNavigate } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import useCounterCountdown from '../../src/hooks/useCountdown';

export function VerifyAccount() {
  const user = useStore((state) => state.user);
  const token = useStore((state) => state.token);
  const [code, setCode] = React.useState('');
  let navigate = useNavigate();

  const { secs, startCountDown } = useCounterCountdown({
    onExpire: () => {},
    onStart: () => {},
  });

  const verifyOTPReq = useQuery(
    'verifyOTP',
    // @ts-ignore
    async () =>
      verifyOTP({
        body: {
          otp: code,
        },
        accessToken: token?.access_token,
      }),
    {
      enabled: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (_data: LoginSuccessResponse) => {
        navigate('/tabs');
      },
    }
  );

  const resendOTPReq = useQuery(
    'resendOTP',
    // @ts-ignore
    async () =>
      resendOTP({
        body: {
          id: user?.id,
        },
        accessToken: token?.access_token,
      }),
    {
      enabled: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (_data: LoginSuccessResponse) => {},
    }
  );

  useEffect(() => {
    startCountDown([0, 1, 0]);
  }, []);
  //
  return (
    <AuthLayout
      title="Register"
      subtitle={`Enter OTP sent to +${user?.phone_code} ${user?.mobile_number}`}
      body={
        <form
          action=""
          className="w-full space-y-6 flex flex-col items-center"
          onSubmit={(e) => {
            e.preventDefault();
            verifyOTPReq.refetch({ throwOnError: true });
          }}
        >
          <label
            htmlFor=""
            className={`text-sm font-bold text-[
                        "white"
                    ] block`}
          >
            OTP Code
          </label>
          <ReactCodeInput
            onChange={(code) => {
              setCode(code);
            }}
            type="number"
            fields={4}
            name={'jdlkf'}
            inputMode={'tel'}
          />
          <div className="border-gray-300 roudned mt-1">
            {secs == '00' ? (
              <div className="flex items-center justify-center">
                <div>
                  <div className="font-medium text-sm text-white">
                    Request another in {`${secs}`}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className="font-medium text-sm text-white"
                  onClick={() => {
                    resendOTPReq.refetch({
                      throwOnError: true,
                    });
                  }}
                >
                  Request another
                </div>
              </div>
            )}
          </div>
          <div className="w-full ">
            <button className="w-full py-2 px-4 bg-[#0044AA] rounded-md text-white text-sm">
              Submit
            </button>
          </div>
        </form>
      }
    />
  );
}
