import { useEffect, useRef, useState } from "react";

const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default function useCounterCountdown({
    onExpire = () => {},
    onStart = () => {},
}: {
    onExpire?: () => void;
    onStart?: () => void;
}) {
    const [countingState, setCountingState] = useState<
        "idle" | "counting" | "expired"
    >("idle");

    const [[hrs, mins, secs], setTime] = useState([0, 0, 0]);
    const previousTimeValue = usePrevious([hrs, mins, secs]);

    useEffect(() => {
        if (
            previousTimeValue &&
            previousTimeValue[0] == 0 &&
            previousTimeValue[1] == 0 &&
            previousTimeValue[2] == 1
        ) {
            onExpire();
            setCountingState("expired");
        }
    }, [hrs, mins, secs]);

    const tick = () => {
        if (hrs === 0 && mins === 0 && secs === 0) {
        } else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    const startCountDown = (duration: any) => {
        onStart && onStart();
        setCountingState("counting");
        setTime(duration);
    };

    return {
        hrs: hrs.toString().padStart(2, "0"),
        mins: mins.toString().padStart(2, "0"),
        secs: secs.toString().padStart(2, "0"),
        previousTimeValue,
        countingState,
        setCountingState,
        setTime,
        startCountDown,
    };
}
