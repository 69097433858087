import { useQuery } from "react-query";
import * as _ from "lodash";
import { changePassword } from "../../src/services/account";
import { ChangePasswordReq } from "../../src/services/request-types/changePasswordReq";

interface ChangePasswordInputMap extends ChangePasswordReq {}

export interface ChangePasswordMap {
    data: ChangePasswordInputMap;
    accessToken: string;
    userId: string;
}

export default function useChangePassword({
    data,
    accessToken,
    userId,
}: ChangePasswordMap) {
    const changePasswordRequest = useQuery(
        "changePassword",
        () =>
            changePassword({
                userId,
                data,
                accessToken: accessToken!,
            }),
        {
            enabled: false,
        }
    );

    return changePasswordRequest;
}
