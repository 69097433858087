import _ from 'lodash';
import { addRecepient, getRecipients, updateRecepient } from './recipients';
import { WithAccessTokenAndData } from './request-types/general';
import {
  MmTopupTransactionReq,
  PaymentMethodUserDetailsReq,
  TransactionReq,
} from './request-types/transactionReq';
import { Recipient } from './response-types/recipientsRes';
import { RecipientTransaction } from './response-types/recipientTransactions';
import { PaymentSettings } from './response-types/settingsRes';
import {
  TransactionRes,
  VerifyPaymentMethodUserDetailsRes,
} from './response-types/transactionRes';
import { UserTransactions } from './response-types/userTransactionsRes';

var axios = require('axios');

export async function verifyPaymentMethodUserDetails({
  accessToken,
  data,
}: WithAccessTokenAndData<PaymentMethodUserDetailsReq>): Promise<
  Recipient & { verification_meta: { first_name: string; last_name: string } }
> {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payment-methods/verify`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then(async function (
    response: any
  ): Promise<
    Recipient & { verification_meta: { first_name: string; last_name: string } }
  > {
    let verifiedRes: VerifyPaymentMethodUserDetailsRes = response.data;

    console.log('verifiedRes:', verifiedRes.meta);

    let recipients = await getRecipients({ accessToken });

    let detailsKey = `${data.payment_method_code}_details`.toLowerCase();

    let userIdObj = verifiedRes.meta.id ? { user_id: verifiedRes.meta.id } : {};

    // get recipient by phone number
    let recipient = _.find(
      recipients.data,
      (recipient) => recipient.mobile_number == data.meta.mobile_number
    );

    const firstName = verifiedRes.meta.first_name;
    const lastName = verifiedRes.meta.last_name;

    if (recipient) {
      // const matchingPaymentMethod = recipient.payment_methods.find(
      //     (paymentMethod) => {
      //         // @ts-ignore
      //         let paymentMethodMetaDetailsKey =
      //             // @ts-ignore
      //             paymentMethod.meta && paymentMethod.meta[detailsKey];
      //         if (paymentMethodMetaDetailsKey) {
      //             // @ts-ignore
      //             let hasMatchingPhoneCodeAndMobileNumber =
      //                 paymentMethodMetaDetailsKey.mobile_number ==
      //                     data.meta.mobile_number &&
      //                 paymentMethodMetaDetailsKey.phone_code ==
      //                     data.meta.phone_code;

      //             if (verifiedRes.meta && verifiedRes.meta.id) {
      //                 return (
      //                     paymentMethodMetaDetailsKey.user_id ==
      //                     verifiedRes.meta.id
      //                 );
      //             }
      //             return true;
      //         }
      //         return false;
      //     }
      // );

      const matchingPaymentMethodIndex = recipient.payment_methods.findIndex(
        (paymentMethod) => {
          return paymentMethod.id == data.payment_method_id;
        }
      );

      recipient.payment_methods.splice(matchingPaymentMethodIndex, 1);

      const updatedRecepient = await updateRecepient({
        accessToken,
        data: {
          id: recipient.id,
          country_code: recipient.country_code,
          first_name: recipient.first_name,
          last_name: recipient.last_name,
          mobile_number: recipient.mobile_number,
          phone_code: recipient.phone_code,
          payment_methods: [
            ...recipient.payment_methods.map((paymentMethod) => {
              return {
                payment_method_id: paymentMethod.id,
                meta: paymentMethod.meta,
              };
            }),
            {
              payment_method_id: data.payment_method_id,
              meta: {
                [detailsKey]: {
                  ...data.meta,
                  ...userIdObj,
                  ...verifiedRes.meta,
                  account_name: `${firstName} ${lastName}`,
                },
              },
            },
          ],
        },
      });

      return {
        ...updatedRecepient,
        verification_meta: {
          ...verifiedRes.meta,
        },
      };
    } else {
      const addedRecepient = await addRecepient({
        accessToken,
        data: {
          country_code: data.country_code,
          first_name: firstName,
          last_name: lastName ? lastName : data.country_code,
          mobile_number: data.meta.mobile_number,
          phone_code: data.meta.phone_code,
          payment_method_id: data.payment_method_id,
          meta: {
            ...data.meta,
            ...userIdObj,
            account_name: `${firstName} ${lastName}`,
          },
        },
      });

      return {
        ...addedRecepient,
        verification_meta: {
          ...verifiedRes.meta,
        },
      };
    }

    // if exists, check if they have the payment method...if they dont, add it to their payment methods and save
    // else create a new recipient with payment method and return them
    // return recipient with recipient id in it
  });
}

export async function loadInfiniteTransactionsAction({
  page = 1,
  accessToken,
}: any): Promise<UserTransactions> {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/transactions?limit=10&page=${page}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function loadTransactionDetails({
  transactionId,
  accessToken,
}: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/transactions/${transactionId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function loadRecepientTransactions({
  recipient,
  accessToken,
}: {
  recipient: Recipient;
  accessToken: string;
}): Promise<RecipientTransaction[]> {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients/${recipient.id}/transactions`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function loadTransactionSettings() {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v2/app/settings`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(function (response: { data: PaymentSettings }) {
    return response.data;
  });
}

export async function makeTransaction({
  accessToken,
  body: {
    recipient_id,
    transaction_type_code,
    sending,
    sending_from,
    sending_to,
    payment_method_code,
  },
}: {
  accessToken: string;
  body: TransactionReq;
}): Promise<TransactionRes> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payments/send`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      recipient_id,
      transaction_type_code,
      sending,
      sending_from,
      sending_to,
      payment_method_code,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function makeTopupTransaction({
  accessToken,
  body,
}: {
  accessToken: string;
  body: MmTopupTransactionReq;
}): Promise<TransactionRes> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payments/mobile-money/top-up`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function makeMMTopupTransaction({
  accessToken,
  body: { transaction_type_code, amount, method, phone_code, mobile_number },
}: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payments/send`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      transaction_type_code,
      amount,
      method,
      phone_code,
      mobile_number,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function makeCardTopupTransaction({
  accessToken,
  body: {
    transaction_type_code,
    method,
    amount,
    reference_number,
    transaction_id,
    email,
  },
}: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payments/send`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      transaction_type_code,
      method,
      amount,
      reference_number,
      transaction_id,
      email,
    },
  }).then(function (response: any) {
    return response.data;
  });
}
