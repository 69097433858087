import React from "react";
import { Button } from "../../src/components/Button";
import { AuthLayout } from "../../src/layouts/AuthLayout";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import ReactLogo from "../assets/svgs/welcome_svg.svg";

export function WelcomePage() {
    let navigate = useNavigate();

    return (
        <AuthLayout
            title="Welcome to Cowriex"
            subtitle="Transfer your money abroad in a few minutes"
            body={
                <div>
                    <div className="flex justify-center">
                        <img src={ReactLogo} alt="logo" />
                    </div>
                    <Button
                        label="Create Account"
                        className="mt-12"
                        onClick={() => {
                            navigate("/register");
                        }}
                    />
                    <Button
                        label="Sign In"
                        className="mt-6"
                        bgColor="white"
                        textColor="[#0044AA]"
                        onClick={() => {
                            navigate("/login");
                        }}
                    />
                </div>
            }
        />
    );
}
