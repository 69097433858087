import { UpdateProfileMap } from 'src/hooks/useUpdateProfile';
import { ChangePasswordMap } from '../../src/hooks/useChangePassword';
import { LoginSuccessResponse } from '../../src/types/account';
import { WithAccessTokenOnly } from './request-types/general';
import {
  MeRes,
  ResetPasswordReq,
  ResetPasswordRes,
} from './response-types/accountRes';
import { ChangePasswordRes } from './response-types/changePasswordRes';
import { UpdateProfileRes } from './response-types/updateProfileRes';

var axios = require('axios');

export async function loginUser({
  data,
}: {
  data?: { mobile_number: string; phone_code: string; password: string };
}): Promise<LoginSuccessResponse | any> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/auth/login`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function changePassword(
  input: ChangePasswordMap
): Promise<ChangePasswordRes | any> {
  console.log('Change Password Input:', input);

  return await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/${input.userId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${input.accessToken}`,
    },
    data: input.data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function updateProfile(
  input: UpdateProfileMap
): Promise<UpdateProfileRes | any> {
  return await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/${input.userId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${input.accessToken}`,
    },
    data: input.data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function updateWallet({
  accessToken,
}: WithAccessTokenOnly): Promise<MeRes> {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/me`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function resendOTP({ body, accessToken }: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/${body['id']}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function verifyOTP({ body, accessToken }: any) {
  return await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/mobile/verify`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function registerUser({ body }: any) {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/auth/register`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function updateUser({ body, accessToken }: any) {
  return await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/${body['id']}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function passwordReset({
  body,
}: {
  body: ResetPasswordReq;
}): Promise<ResetPasswordRes> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/auth/reset-password`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function loadUserWalletDataAndHome({ body, accessToken }: any) {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/users/me`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}
