import React from 'react';

export default function FlexibleMiddleContent({
  TopContent,
  SubHeaderContent,
  BodyContent,
}: {
  TopContent?: JSX.Element;
  SubHeaderContent?: JSX.Element;
  BodyContent?: JSX.Element;
}) {
  return (
    <div className="flex flex-col h-[calc(100vh-170px)]  w-[100vw] md:w-[600px] ">
      {TopContent && (
        <div className="max-h-[125px] h-[calc(100vw/4)] bg-white mt-6 w-[100%]">
          {TopContent}
        </div>
      )}
      {SubHeaderContent && (
        <div className="w-[100%] bg-white mt-3 border-b-4">
          {SubHeaderContent}
        </div>
      )}
      <div className="flex-col flex-1 overflow-y-scroll ">{BodyContent}</div>
    </div>
  );
}
