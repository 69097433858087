import { useQuery } from "react-query";
import * as _ from "lodash";
import { verifyPaymentMethodUserDetails } from "../../src/services/transactions";
import { PaymentMethodUserDetailsReq } from "../../src/services/request-types/transactionReq";

export default function useVerifyPaymentMethodUserDetailsRequest({
    data,
    accessToken,
}: {
    accessToken: string;
    data: PaymentMethodUserDetailsReq;
}) {
    const verifyPaymentMethodUserDetailsRequest = useQuery(
        "verifyPaymentMethodUserDetails",
        () =>
            verifyPaymentMethodUserDetails({
                data,
                accessToken: accessToken!,
            }),
        {
            enabled: false,
        }
    );

    return verifyPaymentMethodUserDetailsRequest;
}
