var axios = require('axios');

export async function loadCountriesFromAPI({ accessToken: _accessToken }: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/countries?filter=enabled`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(function (response: any) {
    return response.data;
  });
}
