import { WithAccessTokenAndData } from './request-types/general';
import {
  RecipientCreate,
  RecipientUpdate,
} from './request-types/recipientsReq';
import { Recipient, RecipientsRes } from './response-types/recipientsRes';

var axios = require('axios');

export async function updateRecepient({
  data,
  accessToken,
}: WithAccessTokenAndData<RecipientUpdate>): Promise<Recipient> {
  return await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients/${data['id']}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function deleteRecepient({ body, accessToken }: any) {
  return await axios({
    method: 'delete',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients/${body['id']}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(function (response: any) {
    return response.data;
  });
}

export async function addRecepientViaSelect({
  data,
  accessToken,
}: WithAccessTokenAndData<RecipientUpdate>): Promise<Recipient> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function addRecepient({
  data,
  accessToken,
}: WithAccessTokenAndData<RecipientCreate>): Promise<Recipient> {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  }).then(function (response: any) {
    return response.data;
  });
}

export async function getRecipients({
  body,
  accessToken,
}: any): Promise<RecipientsRes> {
  const recipientsRes = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/recipients`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  });
  return recipientsRes.data;
}

export async function getRecepientOrFail({ body, accessToken }: any) {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v3/app/payment-methods/verify`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }).then(function (response: any) {
    return response.data;
  });
}
