import React, { useRef, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
// import { TextInput } from "../components/TextInput";
import { StatefulButton } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { FieldCard } from 'src/components/FieldCard';
import { AmountFlag } from 'src/components/AmountFlag';
import { useStore } from 'src/store/store';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from 'src/components/PendingModal';
import { TextInput } from 'src/components/TextInput';
var axios = require('axios');

interface FormData {
  amount: string;
  email: string;
}

export function TopUpPageViaCard() {
  const user = useStore((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const token = useStore((state) => state.token);
  const submitFormRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [showCardPaymentDone, setShowCardPaymentDone] = useState(false);
  // const [showModalSheet, setShowModalSheet] = useState(false);
  const [CXRefNo, setCXRefNo] = useState();
  var apiUrl = `${process.env.REACT_APP_BASE_URL}`;

  const { register, handleSubmit, control, getValues } = useForm<FormData>({
    defaultValues: {
      email: user?.email,
    },
  });

  useWatch({ control, name: 'amount' });
  useWatch({ control, name: 'email' });

  const onSubmit = handleSubmit((formData: FormData) => {
    setIsProcessing(true);
    if (formData.amount) {
      console.log(formData.amount);

      var headerMap = {
        headers: {
          authorization: `Bearer ${token?.access_token}`,
        },
      };
      axios
        .post(
          apiUrl + '/api/v3/app/payments/card/top-up/request',
          {},
          headerMap
        )
        .then((res: any) => {
          console.log(res);
          var referenceNumber = res['data']['reference_number'];

          setCXRefNo(referenceNumber);
          handleFlutterPayment({
            callback: (data) => {
              let postData = {
                transaction_type_code: 'topup',
                method: 'card',
                amount: Number(formData.amount),
                reference_number: referenceNumber,
                transaction_id: data['transaction_id'],
                email: user!.email!,
              };
              console.log(postData);
              axios
                .post(
                  apiUrl + '/api/v3/app/payments/card/top-up',
                  postData,
                  headerMap
                )
                .then((data: any) => {
                  console.log(data);
                  closePaymentModal();
                  setShowCardPaymentDone(true);
                  setIsProcessing(false);
                });
            },
            onClose() {
              console.log('Closed');
              setIsProcessing(false);
            },
          });
        })
        .catch(() => {
          setIsProcessing(false);
        });
    }
  });

  const getCountryAndCurrencyCode = () => {
    const currencyCode = user!.country!.currency_code;
    const countryCode = user?.country_code;

    if (countryCode?.toLowerCase() == 'sz') {
      return { countryCode: 'ZA', currencyCode: 'ZAR' };
    }

    return { countryCode, currencyCode };
  };

  const { currencyCode, countryCode } = getCountryAndCurrencyCode();

  const handleFlutterPayment = useFlutterwave({
    public_key:
      user?.phone_code == '256'
        ? process.env.REACT_APP_FLUTTERWAVE_UG_PUBLIC_KEY!
        : process.env.REACT_APP_FLUTTERWAVE_ZA_PUBLIC_KEY!,
    tx_ref: `${CXRefNo}`,
    amount: Number(getValues().amount),
    currency: currencyCode,
    payment_options: 'card',
    // @ts-ignore
    country: countryCode,
    customer: {
      email: user?.email ?? getValues().email,
      phone_number: user!.mobile_number!,
      name: user!.first_name + ' ' + user!.last_name,
    },
    customizations: {
      title: 'Top Up your  Wallet',
      description: 'Topping up',
      logo: 'https://app/images/flutterwave_logo.png',
    },
  });

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Top Up via Card
          </p>
        </div>
      }
      MiddleContent={
        <>
          {showCardPaymentDone && (
            <CustomModal
              headerText="Topup Posted"
              bodyText="Your account will be topped up. \n You may close this window."
              onClose={() => {
                navigate('/tabs', {
                  replace: true,
                });
              }}
            />
          )}
          <section className="h-[calc(100%-130px)] bg-black">
            <div className="h-[100%] w-[100%] bg-white mt-3">
              <div className="w-full flex items-center justify-between">
                <form
                  action=""
                  onSubmit={onSubmit}
                  // @ts-ignore
                  ref={submitFormRef}
                  className="w-full"
                >
                  <FieldCard
                    FieldContent={
                      <div className="w-full">
                        <AmountFlag
                          countryCode={user?.country.code!}
                          label="Amount"
                          name="amount"
                          control={control}
                          type="number"
                          currency={user?.country.currency_code!}
                          register={register}
                        />
                      </div>
                    }
                  />
                  <FieldCard
                    FieldContent={
                      <TextInput
                        label="Email"
                        name="email"
                        type="text"
                        textColor="black"
                        inputClassName="text-black"
                        register={register}
                      />
                    }
                  />
                </form>
              </div>
            </div>
          </section>
        </>
      }
      BottomButtons={
        <StatefulButton
          type="submit"
          idleText="Top Up"
          processingText="Processing..."
          className="h-12"
          isFetching={isProcessing}
          onClick={() => {
            if (submitFormRef && submitFormRef.current) {
              // submitFormRef.current.preventDefault();
              console.log('Dispatching event');

              submitFormRef.current.dispatchEvent(
                new Event('submit', {
                  cancelable: true,
                  bubbles: true,
                })
              );
            }
          }}
        />
      }
    />
  );
}
