import { useQuery } from 'react-query';
import * as _ from 'lodash';
import { getRecipients } from '../../src/services/recipients';

export interface GetRecipientsInputMap {
  accessToken: string;
}

export default function useGetRecipients({
  accessToken,
}: GetRecipientsInputMap) {
  const getRecipientsRequest = useQuery(
    'getRecipients',
    () =>
      getRecipients({
        accessToken: accessToken!,
      }),
    {
      enabled: true,
    }
  );

  return { getRecipientsRequest };
}
