import { useQuery } from "react-query";
import * as _ from "lodash";
import { updateProfile } from "../../src/services/account";
import { UpdateProfileReq } from "../../src/services/request-types/updateProfileReq";

interface UpdateProfileInputMap extends UpdateProfileReq {}

export interface UpdateProfileMap {
    data: UpdateProfileInputMap;
    accessToken: string;
    userId: string;
}

export default function useUpdateProfile({
    data,
    accessToken,
    userId,
}: UpdateProfileMap) {
    const updateProfileRequest = useQuery(
        "updateProfile",
        () =>
            updateProfile({
                userId,
                data,
                accessToken: accessToken!,
            }),
        {
            enabled: false,
        }
    );

    return updateProfileRequest;
}
