import { PaymentSettings } from "../../src/services/response-types/settingsRes";

export const NOT_ENOUGH_FUNDS_ERROR = "not_enough_funds";

export const paymentSettings: PaymentSettings = {
    payments: {
        service_fee: 0.05,
        minimum_fee: 30,
        telecom_fees: {
            mtn: 390,
            airtel: 300,
        },
    },
    exchange_rates: {
        ZA: {
            UG: "208",
            USD: "14.8",
        },
    },
    forex_rates: {
        ZAR: {
            UGX: {
                app_rate: "240",
                market_rate: "235",
            },
            ZAR: {
                app_rate: "1",
                market_rate: "1",
            },
        },
        UGX: {
            ZAR: {
                app_rate: "0.998",
                market_rate: "0.667",
            },
            UGX: {
                app_rate: "1",
                market_rate: "1",
            },
        },
    },
    payment_routes: [
        {
            id: 1,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 6,
            payment_method_id: 1,
            status: 1,
            created_at: "2020-03-22T10:45:46.000000Z",
            updated_at: "2021-06-27T18:43:05.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 1,
                name: "MTN Mobile Money",
                code: "MoMo",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 2,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 5,
            payment_method_id: 2,
            status: 1,
            created_at: "2020-03-22T10:50:17.000000Z",
            updated_at: "2021-06-27T18:43:20.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 2,
                name: "Airtel Mobile Money",
                code: "AirtelMoney",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 3,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 5,
            payment_method_id: 5,
            status: 1,
            created_at: "2020-03-22T10:54:10.000000Z",
            updated_at: "2021-06-26T18:36:21.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 5,
                name: "Bank Account",
                code: "BankAccount",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 4,
            source_country_id: 232,
            destination_country_id: 204,
            source_currency_code: "UGX",
            destination_currency_code: "ZAR",
            service_fee: 0,
            payment_method_id: 3,
            status: 0,
            created_at: "2020-03-22T10:56:52.000000Z",
            updated_at: "2021-11-18T09:29:33.000000Z",
            minimum_service_fee: 0,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 3,
                name: "CowrieX Wallet",
                code: "Wallet",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 5,
            source_country_id: 232,
            destination_country_id: 204,
            source_currency_code: "UGX",
            destination_currency_code: "ZAR",
            service_fee: 5.5,
            payment_method_id: 4,
            status: 0,
            created_at: "2020-03-22T11:03:54.000000Z",
            updated_at: "2021-10-26T13:16:06.000000Z",
            minimum_service_fee: 5000,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 4,
                name: "FNB eWallet",
                code: "FNB_E_Wallet",
                created_at: "2020-03-15T18:28:40.000000Z",
                updated_at: "2020-03-15T18:28:40.000000Z",
                payment_method_category_code: null,
            },
        },
        {
            id: 6,
            source_country_id: 232,
            destination_country_id: 204,
            source_currency_code: "UGX",
            destination_currency_code: "ZAR",
            service_fee: 0,
            payment_method_id: 5,
            status: 0,
            created_at: "2020-03-22T11:08:00.000000Z",
            updated_at: "2021-11-26T10:07:14.000000Z",
            minimum_service_fee: 0,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 5,
                name: "Bank Account",
                code: "BankAccount",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 7,
            source_country_id: 204,
            destination_country_id: 204,
            source_currency_code: "ZAR",
            destination_currency_code: "ZAR",
            service_fee: 30,
            payment_method_id: 4,
            status: 0,
            created_at: "2020-03-23T19:37:22.000000Z",
            updated_at: "2021-10-26T13:16:02.000000Z",
            minimum_service_fee: 30,
            is_percentage: 0,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 4,
                name: "FNB eWallet",
                code: "FNB_E_Wallet",
                created_at: "2020-03-15T18:28:40.000000Z",
                updated_at: "2020-03-15T18:28:40.000000Z",
                payment_method_category_code: null,
            },
        },
        {
            id: 8,
            source_country_id: 204,
            destination_country_id: 204,
            source_currency_code: "ZAR",
            destination_currency_code: "ZAR",
            service_fee: 2,
            payment_method_id: 5,
            status: 1,
            created_at: "2020-03-23T19:52:42.000000Z",
            updated_at: "2020-03-23T19:52:42.000000Z",
            minimum_service_fee: 10,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 5,
                name: "Bank Account",
                code: "BankAccount",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 9,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 2,
            payment_method_id: 5,
            status: 1,
            created_at: "2020-03-23T20:29:04.000000Z",
            updated_at: "2020-03-23T20:29:04.000000Z",
            minimum_service_fee: 3000,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 5,
                name: "Bank Account",
                code: "BankAccount",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 10,
            source_country_id: 204,
            destination_country_id: 204,
            source_currency_code: "ZAR",
            destination_currency_code: "ZAR",
            service_fee: 5,
            payment_method_id: 3,
            status: 1,
            created_at: "2020-03-23T20:31:32.000000Z",
            updated_at: "2020-03-23T20:31:32.000000Z",
            minimum_service_fee: 5,
            is_percentage: 0,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 3,
                name: "CowrieX Wallet",
                code: "Wallet",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 11,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 2000,
            payment_method_id: 4,
            status: 0,
            created_at: "2020-03-23T20:41:20.000000Z",
            updated_at: "2021-10-26T13:15:40.000000Z",
            minimum_service_fee: 2000,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 4,
                name: "FNB eWallet",
                code: "FNB_E_Wallet",
                created_at: "2020-03-15T18:28:40.000000Z",
                updated_at: "2020-03-15T18:28:40.000000Z",
                payment_method_category_code: null,
            },
        },
        {
            id: 12,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 1,
            payment_method_id: 3,
            status: 1,
            created_at: "2020-03-23T20:51:57.000000Z",
            updated_at: "2020-03-23T20:51:57.000000Z",
            minimum_service_fee: 300,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 3,
                name: "CowrieX Wallet",
                code: "Wallet",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 13,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 3,
            payment_method_id: 1,
            status: 1,
            created_at: "2020-03-23T21:09:13.000000Z",
            updated_at: "2020-03-23T21:09:13.000000Z",
            minimum_service_fee: 7000,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 1,
                name: "MTN Mobile Money",
                code: "MoMo",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 14,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 3,
            payment_method_id: 2,
            status: 1,
            created_at: "2020-03-23T21:10:28.000000Z",
            updated_at: "2020-03-23T21:10:28.000000Z",
            minimum_service_fee: 7000,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 2,
                name: "Airtel Mobile Money",
                code: "AirtelMoney",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 15,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 1,
            payment_method_id: 3,
            status: 1,
            created_at: "2020-03-23T21:16:13.000000Z",
            updated_at: "2020-03-23T21:16:13.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 3,
                name: "CowrieX Wallet",
                code: "Wallet",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 16,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 1,
            payment_method_id: 4,
            status: 0,
            created_at: "2020-03-23T21:19:30.000000Z",
            updated_at: "2021-10-26T13:15:44.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 4,
                name: "FNB eWallet",
                code: "FNB_E_Wallet",
                created_at: "2020-03-15T18:28:40.000000Z",
                updated_at: "2020-03-15T18:28:40.000000Z",
                payment_method_category_code: null,
            },
        },
        {
            id: 17,
            source_country_id: 213,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 5,
            payment_method_id: 1,
            status: 1,
            created_at: "2020-04-19T19:37:26.000000Z",
            updated_at: "2020-04-19T19:37:29.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 213,
                name: "Swaziland",
                name_official: "Kingdom of Swaziland",
                code: "SZ",
                iso3: "SWZ",
                phone_code: 268,
                lat: "-26.53892570",
                lng: "31.47960891",
                flag_32: "SZ-32.png",
                flag_128: "SZ-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:11.000000Z",
                currency_code: "SZL",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 1,
                name: "MTN Mobile Money",
                code: "MoMo",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 18,
            source_country_id: 213,
            destination_country_id: 204,
            source_currency_code: "ZAR",
            destination_currency_code: "ZAR",
            service_fee: 0,
            payment_method_id: 3,
            status: 1,
            created_at: "2020-04-21T14:18:31.000000Z",
            updated_at: "2020-04-21T14:18:34.000000Z",
            minimum_service_fee: 0,
            is_percentage: 0,
            source_country: {
                id: 213,
                name: "Swaziland",
                name_official: "Kingdom of Swaziland",
                code: "SZ",
                iso3: "SWZ",
                phone_code: 268,
                lat: "-26.53892570",
                lng: "31.47960891",
                flag_32: "SZ-32.png",
                flag_128: "SZ-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:11.000000Z",
                currency_code: "SZL",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 3,
                name: "CowrieX Wallet",
                code: "Wallet",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
        {
            id: 19,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 5,
            payment_method_id: 11,
            status: 1,
            created_at: "2021-10-25T12:43:47.000000Z",
            updated_at: "2021-10-25T12:43:47.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 11,
                name: "UMEME Yaka",
                code: "UMEME_YAKA",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 20,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 10,
            payment_method_id: 11,
            status: 1,
            created_at: "2021-10-25T12:45:39.000000Z",
            updated_at: "2021-10-25T12:45:39.000000Z",
            minimum_service_fee: 20000,
            is_percentage: 1,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 11,
                name: "UMEME Yaka",
                code: "UMEME_YAKA",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 21,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 30000,
            payment_method_id: 8,
            status: 0,
            created_at: "2021-10-25T12:49:15.000000Z",
            updated_at: "2021-11-10T07:04:57.000000Z",
            minimum_service_fee: 10000,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 8,
                name: "DSTV",
                code: "DSTV",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 22,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 1000,
            payment_method_id: 9,
            status: 0,
            created_at: "2021-10-26T13:15:24.000000Z",
            updated_at: "2021-11-10T07:04:56.000000Z",
            minimum_service_fee: 1000,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 9,
                name: "GOTV",
                code: "GOTV",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 23,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 100,
            payment_method_id: 14,
            status: 0,
            created_at: "2021-10-26T13:26:23.000000Z",
            updated_at: "2021-11-10T07:05:04.000000Z",
            minimum_service_fee: 100,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 14,
                name: "Zuku",
                code: "ZUKU",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 24,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 100,
            payment_method_id: 12,
            status: 0,
            created_at: "2021-10-26T13:27:05.000000Z",
            updated_at: "2021-11-10T07:04:47.000000Z",
            minimum_service_fee: 100,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 12,
                name: "National Water Sewerage Corporation",
                code: "NWSC",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 25,
            source_country_id: 232,
            destination_country_id: 232,
            source_currency_code: "UGX",
            destination_currency_code: "UGX",
            service_fee: 100,
            payment_method_id: 10,
            status: 0,
            created_at: "2021-10-26T13:27:33.000000Z",
            updated_at: "2021-11-10T07:05:06.000000Z",
            minimum_service_fee: 100,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 10,
                name: "STARTIMES",
                code: "STARTIMES",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 26,
            source_country_id: 232,
            destination_country_id: 204,
            source_currency_code: "UGX",
            destination_currency_code: "ZAR",
            service_fee: 0,
            payment_method_id: 11,
            status: 1,
            created_at: "2021-11-18T09:32:10.000000Z",
            updated_at: "2021-11-18T09:32:17.000000Z",
            minimum_service_fee: 0,
            is_percentage: 0,
            source_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            destination_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            source_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 11,
                name: "UMEME Yaka",
                code: "UMEME_YAKA",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 27,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 3.5,
            payment_method_id: 7,
            status: 1,
            created_at: "2021-11-26T15:49:58.000000Z",
            updated_at: "2021-11-26T15:49:58.000000Z",
            minimum_service_fee: 3.5,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 7,
                name: "AIRTIME",
                code: "Airtime",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 28,
            source_country_id: 204,
            destination_country_id: 232,
            source_currency_code: "ZAR",
            destination_currency_code: "UGX",
            service_fee: 3.5,
            payment_method_id: 8,
            status: 1,
            created_at: "2021-11-26T15:51:01.000000Z",
            updated_at: "2021-11-26T15:51:01.000000Z",
            minimum_service_fee: 3.5,
            is_percentage: 1,
            source_country: {
                id: 204,
                name: "South Africa",
                name_official: "Republic of South Africa",
                code: "ZA",
                iso3: "ZAF",
                phone_code: 27,
                lat: "-27.17706863",
                lng: "24.50856092",
                flag_32: "ZA-32.png",
                flag_128: "ZA-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "ZAR",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "ZAR",
                symbol: "R",
                name: "South African Rand",
                symbol_native: "R",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "South African rand",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 8,
                name: "DSTV",
                code: "DSTV",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "utility",
            },
        },
        {
            id: 29,
            source_country_id: 213,
            destination_country_id: 232,
            source_currency_code: "SZL",
            destination_currency_code: "UGX",
            service_fee: 5,
            payment_method_id: 1,
            status: 1,
            created_at: "2021-12-13T14:59:05.000000Z",
            updated_at: "2021-12-13T14:59:07.000000Z",
            minimum_service_fee: 30,
            is_percentage: 1,
            source_country: {
                id: 213,
                name: "Swaziland",
                name_official: "Kingdom of Swaziland",
                code: "SZ",
                iso3: "SWZ",
                phone_code: 268,
                lat: "-26.53892570",
                lng: "31.47960891",
                flag_32: "SZ-32.png",
                flag_128: "SZ-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:11.000000Z",
                currency_code: "SZL",
            },
            destination_country: {
                id: 232,
                name: "Uganda",
                name_official: "Republic of Uganda",
                code: "UG",
                iso3: "UGA",
                phone_code: 256,
                lat: "1.54760620",
                lng: "32.44409759",
                flag_32: "UG-32.png",
                flag_128: "UG-128.png",
                created_at: "2019-10-18T09:30:08.000000Z",
                updated_at: "2021-05-21T19:12:10.000000Z",
                currency_code: "UGX",
            },
            source_currency: {
                code: "SZL",
                symbol: "SZL",
                name: "Swazi Lilangeni",
                symbol_native: "SZL‏",
                decimal_digits: "2",
                rounding: 0,
                name_plural: "Emalangeni",
                created_at: "2020-05-18T17:30:50.000000Z",
                updated_at: "2020-05-18T17:30:50.000000Z",
            },
            destination_currency: {
                code: "UGX",
                symbol: "USh",
                name: "Ugandan Shilling",
                symbol_native: "USh",
                decimal_digits: "0",
                rounding: 0,
                name_plural: "Ugandan shillings",
                created_at: "2019-10-18T09:30:10.000000Z",
                updated_at: "2019-10-18T09:30:10.000000Z",
            },
            payment_method: {
                id: 1,
                name: "MTN Mobile Money",
                code: "MoMo",
                created_at: "2022-06-15T16:40:38.000000Z",
                updated_at: "2022-06-15T16:40:38.000000Z",
                payment_method_category_code: "sendMoney",
            },
        },
    ],
};
