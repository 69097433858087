import React, { useEffect, useRef, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../components/TextInput';
import { StatefulButton } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { FieldCard } from '../../src/components/FieldCard';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import useChangePassword from 'src/hooks/useChangePassword';
import { useStore } from 'src/store/store';
import { SuccessModal } from 'src/components/SuccessModal';
import { useNavigate } from 'react-router-dom';

interface FormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export function ChangePassword() {
  const { register, handleSubmit, getValues, control } = useForm<FormData>();
  const [requestSuccessful, setRequestSuccessful] = useState(false);
  const submitFormRef = useRef<HTMLFormElement>();
  const navigation = useNavigate();
  const { userId, accessToken } = useStore((state) => {
    return {
      userId: state?.user?.id,
      accessToken: state?.token?.access_token,
    };
  });

  useWatch({ control, name: 'old_password' });
  useWatch({ control, name: 'password' });
  useWatch({ control, name: 'password_confirmation' });

  const { refetch, isFetching, isSuccess } = useChangePassword({
    userId: `${userId!}`,
    accessToken: accessToken!,
    data: {
      old_password: getValues().old_password,
      password: getValues().password,
      password_confirmation: getValues().password_confirmation,
    },
  });
  const onSubmit = handleSubmit((_data: FormData) => {
    refetch({ throwOnError: true });
  });

  useEffect(() => {
    if (!requestSuccessful) {
      setRequestSuccessful(isSuccess);
    }
  }, [isSuccess]);

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Change Password
          </p>
        </div>
      }
      MiddleContent={
        <>
          {requestSuccessful && (
            <SuccessModal
              bodyText="Password Changed Successfully"
              headerText="Success"
              onClose={() => {
                setRequestSuccessful(false);
                navigation(-1);
              }}
            />
          )}
          <section className="h-[calc(100%-130px)] bg-white">
            <div>
              <div>
                <div className="w-full flex items-center justify-between">
                  <form
                    action=""
                    onSubmit={onSubmit}
                    className="w-full"
                    // @ts-ignore
                    ref={submitFormRef}
                  >
                    <div className="flex items-center h-[100%] justify-center ">
                      {/* <img
                          className="w-10 h-10 rounded-lg"
                          src={user.avatar}
                          alt={user.name}
                      /> */}
                      <div className="flex-1">
                        <FieldCard
                          FieldContent={
                            <TextInput
                              label="Old Password"
                              name="old_password"
                              type="password"
                              textColor="black"
                              inputClassName="text-black"
                              register={register}
                            />
                          }
                        />
                        <FieldCard
                          FieldContent={
                            <TextInput
                              label="New Password"
                              name="password"
                              type="password"
                              textColor="black"
                              inputClassName="text-black"
                              register={register}
                            />
                          }
                        />
                        <FieldCard
                          FieldContent={
                            <TextInput
                              label="Confirm Password"
                              name="password_confirmation"
                              type="password"
                              textColor="black"
                              inputClassName="text-black"
                              register={register}
                            />
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      }
      BottomButtons={
        <StatefulButton
          type="submit"
          idleText="Change"
          processingText="Processing..."
          className="h-12"
          isFetching={isFetching}
          onClick={() => {
            if (submitFormRef && submitFormRef.current) {
              // submitFormRef.current.preventDefault();
              console.log('Dispatching event');

              submitFormRef.current.dispatchEvent(
                new Event('submit', {
                  cancelable: true,
                  bubbles: true,
                })
              );
            }
          }}
        />
      }
    />
  );
}
