import React, { useEffect, useState } from 'react';

import { Button } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { JustifyBetween } from '../../src/components/JustifyBetween';
import { Flag } from '../../src/components/CurrencySymbolFlag';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import useReceiverCountries from 'src/hooks/useReceiverCountries';
import {
  PaymentSettings,
  SourceCountryOrDestinationCountry,
  SourceCountryOrDestinationCountryCodeType,
} from '../../src/services/response-types/settingsRes';
import { useStore } from 'src/store/store';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

export interface SelectCountryParams {
  transferOptionsCategory: string;
  sending_to: SourceCountryOrDestinationCountryCodeType;
  phone_code: number;
  sending_to_currency: string;
  sending_to_country_code: string;
  sending_from_currency: string;
  sending_from_country_code: string;
  paymentSettings: PaymentSettings;
  isCalculateOption: boolean;
}
// sending_to

export function SelectCountry() {
  let navigate = useNavigate();
  const user = useStore((state) => state.user);
  const setTransactionData = useStore((state) => state.setTransactionData);
  const transactionData = useStore((state) => state.transactionData);

  console.log('transactionData:', transactionData);

  const [selectedCountry, setSelectedCountry] = useState<
    Partial<SourceCountryOrDestinationCountry>
  >({
    code: transactionData?.sending_to,
    currency_code: transactionData?.sending_to_currency,
    phone_code: transactionData?.phone_code,
  });
  const { destinationCountries, getPaymentSettingsRequest } =
    useReceiverCountries({
      baseCountryCode: user?.country.code as any,
    });
  let { state }: { state: any } = useLocation();
  let paramsData: {
    transferOptionsCategory: string;
    isCalculateOption: boolean;
  } = state;

  const onSubmit = () => {
    let navStateData = {
      transferOptionsCategory: paramsData.transferOptionsCategory,
      sending_to: selectedCountry?.code,
      destination_currency_code: selectedCountry?.currency_code,
      phone_code: selectedCountry?.phone_code,
      sending_to_currency: selectedCountry?.currency_code,
      sending_to_country_code: selectedCountry?.code,
      sending_from_currency: user?.country.currency_code,
      sending_from_country_code: user?.country.code,
      paymentSettings: getPaymentSettingsRequest.data,
      isCalculateOption: paramsData.isCalculateOption,
    } as SelectCountryParams;

    setTransactionData(navStateData);

    if (selectedCountry) {
      navigate('/customer/transact/step-2/transaction-option', {
        state: navStateData,
      });
    }
  };

  useEffect(() => {
    setTransactionData({});
  }, []);

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Choose Country
          </p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-full">
              {getPaymentSettingsRequest.isFetching &&
                (getPaymentSettingsRequest.data?.payment_routes || []).length ==
                  0 && (
                  <div
                    role="status"
                    className="flex flex-row items-center justify-center h-[200px] mt-[50%]"
                  >
                    <LoadingSpinner description="Loading" />
                  </div>
                )}

              {(destinationCountries || []).map((coutry, index) => (
                <div
                  key={index}
                  className="flex items-center  justify-between shadow-lg m-4 rounded-lg"
                >
                  <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                    <div className="p-4 w-[100%] flex border-b-2">
                      <JustifyBetween
                        Left={
                          <div className="flex items-center">
                            <div className="ml-3 mr-4">
                              <Flag countryCode={coutry.code} />
                            </div>
                            <div>
                              <p className="text-[#0044AA] font-semibold text-lg">
                                {coutry.name}
                              </p>
                              <p className="text-gray-400 font-normal text-base mt-1">
                                {`${coutry.currency_code}`}
                              </p>
                            </div>
                          </div>
                        }
                        Right={
                          <input
                            className={`form-check-input appearance-none rounded-full h-4 w-4 border-2 ${
                              coutry.code != selectedCountry.code
                                ? 'border-gray-300 bg-white'
                                : 'bg-blue-600 border-blue-600'
                            } focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                            type="radio"
                            onChange={() => {
                              setSelectedCountry(coutry);
                            }}
                            name="sending_to"
                            value={coutry.code}
                            id="flexRadioDefault10"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </>
      }
      BottomButtons={
        <Button
          label="Continue"
          className="flex-1"
          bgColor="[#0044AA]"
          textColor="white"
          type="submit"
          onClick={() => {
            onSubmit();
          }}
        />
      }
    />
  );
}
