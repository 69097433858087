/* This example requires Tailwind CSS v2.0+ */
import './styles/bottomSheet.css';
import React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

export default function ModalSheet({
  initialState = false,
  updateParentState,
  modalZIndex = '10',
  HeaderCenterText,
  HeaderRightIcon,
  HeaderLeftIcon,
  HeaderRightOnClick,
  // HeaderLeftOnClick,
  Body,
}: {
  updateParentState: any;
  initialState: boolean;
  modalZIndex?: String;
  HeaderCenterText: String;
  HeaderRightOnClick?: () => void;
  HeaderLeftOnClick?: () => void;
  HeaderRightIcon?: JSX.Element;
  HeaderLeftIcon?: JSX.Element;
  Body: JSX.Element;
}) {
  const [open, setOpen] = useState<boolean>(false);

  const cancelButtonRef = useRef(null);

  const focusRef = useRef();

  useEffect(() => {
    // Setting focus is to aid keyboard and screen reader nav when activating this iframe

    if (focusRef && focusRef.current) {
      // @ts-ignore
      focusRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setOpen(initialState);
  }, [initialState]);

  useEffect(() => {
    updateParentState(open);
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-${modalZIndex || '10'}`}
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-50"
          enterFrom="opacity-0 height-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-${
              modalZIndex || '10'
            }`}
          >
            {/* @ts-ignore */}
            <button
              onClick={() => setOpen((open) => !open)}
              // @ts-ignore
              ref={focusRef}
            >
              {open ? 'Close' : 'Open'}
            </button>

            <BottomSheet
              style={{
                width: 600, // width set in app.css using the role
                zIndex: 1000,
              }}
              open={open}
              className={`z-${modalZIndex || '20'} fixed`}
              onDismiss={() => setOpen(false)}
              blocking={false}
              header={
                <div className="flex flex-row justify-between">
                  <div
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {HeaderLeftIcon}
                  </div>
                  <div>{HeaderCenterText}</div>
                  <div onClick={HeaderRightOnClick}>{HeaderRightIcon}</div>
                </div>
              }
              snapPoints={({ maxHeight }) => [maxHeight / 2, maxHeight * 0.6]}
            >
              {Body}
            </BottomSheet>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
