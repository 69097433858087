import React from "react";

import { JustifyBetween } from "../../src/components/JustifyBetween";
import { BackArrowButton } from "../../src/components/BackArrowButton";
import { LayoutWithoutBottonBtnAndNav } from "../../src/layouts/LayoutWithoutBottonBtnAndNav";
import { useNavigate } from "react-router-dom";

export function Settings() {
    // const { register, handleSubmit } = useForm<FormData>();
    let navigate = useNavigate();
    // // const [showModalSheet, setShowModalSheet] = useState(false);

    // const onSubmit = handleSubmit((data: FormData) => {
    //     console.log(data);
    // });

    let settingsOptions = [
        {
            title: "Change Password",
            destinationPage: "/customer/account/settings/change-password",
            svg: "",
        },
    ];

    return (
        <LayoutWithoutBottonBtnAndNav
            AppBarContent={
                <div className="flex p-5 justify-start items-center">
                    <BackArrowButton />
                    <p className="text-white font-semibold text-2xl pl-5">
                        Settings
                    </p>
                </div>
            }
            MiddleContent={
                <>
                    <section className="h-[calc(100%-130px)] bg-white">
                        <div className="w-full">
                            <div className="flex items-center  justify-between shadow-lg m-4 rounded-lg">
                                <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                                    {/* <img
                                                        className="w-10 h-10 rounded-lg"
                                                        src={user.avatar}
                                                        alt={user.name}
                                                    /> */}

                                    {settingsOptions.map(
                                        (settingsOption, index) => (
                                            <div
                                                key={index}
                                                className="p-4 w-[100%] flex border-b-2 cursor-pointer"
                                                onClick={() => {
                                                    navigate(
                                                        settingsOption.destinationPage
                                                    );
                                                }}
                                            >
                                                <JustifyBetween
                                                    Left={
                                                        <p className="text-[#0044AA] font-semibold text-base">
                                                            {
                                                                settingsOption.title
                                                            }
                                                        </p>
                                                    }
                                                    Right={
                                                        settingsOption.svg ? (
                                                            <p className="text-[#0044AA] font-normal text-base">
                                                                {
                                                                    settingsOption.svg
                                                                }
                                                            </p>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                    {/* <div className="p-4 w-[100%] flex border-b-2">
                                        <JustifyBetween
                                            Left={
                                                <p className="text-[#0044AA] font-normal text-base">
                                                    {"About"}
                                                </p>
                                            }
                                            Right={
                                                <p className="text-[#0044AA] font-normal text-base">
                                                    {"arrow_right"}
                                                </p>
                                            }
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        />
    );
}
