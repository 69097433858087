import React from "react";
import { Flag } from "./CurrencySymbolFlag";
import { JustifyBetween } from "./JustifyBetween";

export function CountryFlagDetailsJustifyBetween({
    headerText,
    countryCode,
    bottomText,
}: {
    headerText?: string;
    bottomText?: string;
    countryCode: string;
}) {
    return (
        <div>
            {headerText && (
                <JustifyBetween
                    Left={
                        <div className="flex items-center">
                            <div className="-ml-4 mr-4">
                                <Flag countryCode={countryCode} />
                            </div>
                            <div>
                                <p className="text-[#0044AA] font-semibold text-lg">
                                    {headerText}
                                </p>
                                {bottomText && (
                                    <p className="text-gray-400 font-normal text-base mt-1">
                                        {bottomText}
                                    </p>
                                )}
                            </div>
                        </div>
                    }
                />
            )}
        </div>
    );
}
