import { useEffect, useState } from 'react';
import {
  ConvertAmountInputMap,
  PaymentRoutesEntity,
  ZAROrUGXOrSZL,
  ZAROrUGXOrSZL_RATE,
} from '../../src/services/response-types/settingsRes';
import { NOT_ENOUGH_FUNDS_ERROR } from './useConvertAmount.test_data';

function useConvertAmount({
  defaultSelectedCurrencyCode,
  defaultDestinationCurrencyCode,
  defaultAmount,
  receiveAmount,
  defaultPaymentMethodId,
  walletBalance,
  paymentSettings,
}: ConvertAmountInputMap) {
  const [selectedCurrencyCode, _setSelectedCurrencyCode] = useState<
    keyof ZAROrUGXOrSZL
  >(defaultSelectedCurrencyCode);
  const [destinationCurrencyCode, _setDestinationCurrencyCode] = useState<
    keyof ZAROrUGXOrSZL
  >(defaultDestinationCurrencyCode);
  const [amount, setAmount] = useState(defaultAmount || 0);
  const [paymentMethodId, _setPaymentMethodId] = useState(
    defaultPaymentMethodId
  );
  // processing
  // get route
  let route: PaymentRoutesEntity = paymentSettings.payment_routes?.find(
    (route) =>
      route.source_currency_code == selectedCurrencyCode &&
      route.destination_currency_code == destinationCurrencyCode &&
      route.payment_method_id == paymentMethodId
  )!;

  // get exchange rate
  let exchangeRate: ZAROrUGXOrSZL_RATE =
    paymentSettings.forex_rates[route.source_currency_code]![
      route.destination_currency_code
    ]!;

  let appExchangeRate = parseFloat(exchangeRate.app_rate);

  useEffect(() => {
    if (defaultAmount != undefined) {
      setAmount(defaultAmount || 0);
    }
  }, [defaultAmount]);

  useEffect(() => {
    if (receiveAmount != undefined) {
      setAmount((receiveAmount || 0) / appExchangeRate);
    }
  }, [receiveAmount]);

  // convert the amount
  let destinationAmount = amount * appExchangeRate;

  // get service fee off the wallet basing on the amount being sent
  let serviceFee = route.is_percentage
    ? amount * route.service_fee * 0.01
    : route.service_fee;

  if (serviceFee < route.minimum_service_fee) {
    serviceFee = route.minimum_service_fee;
  }

  console.log(
    'NWSC, walletBalance, amount + serviceFee:',
    walletBalance,
    amount + serviceFee
  );

  if (walletBalance < amount + serviceFee) {
    return {
      destinationAmount: 0,
      serviceFee: 0,
      amount,
      appExchangeRate,
      error_message: NOT_ENOUGH_FUNDS_ERROR,
      walletBalance,
    };
  } else if (destinationAmount == 0) {
    serviceFee = 0;
  } else {
    walletBalance = walletBalance - amount - serviceFee;
  }

  // output
  // amount to be received
  return {
    destinationAmount,
    serviceFee,
    amount,
    appExchangeRate,
    walletBalance,
    defaultDestinationCurrencyCode,
    defaultSelectedCurrencyCode,
  };
}

export default useConvertAmount;
