import React from 'react';

export function Button({
  label,
  type = undefined,
  bgColor,
  textColor,
  className,
  onClick,
}: {
  label: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
  bgColor?: string;
  textColor?: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div className="w-full">
      <button
        onClick={onClick}
        type={type}
        className={`w-full py-2 h-[50px] px-4 bg-${
          bgColor ?? '[#0044AA]'
        } rounded-md text-${textColor ?? 'white'} text-sm ${className ?? ''}`}
      >
        {label}
      </button>
    </div>
  );
}

export function StatefulButton({
  isFetching,
  idleText,
  type = undefined,
  processingText,
  className,
  onClick,
}: {
  isFetching?: Boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  idleText: string;
  processingText: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button
      type={type}
      disabled={isFetching == undefined ? false : isFetching ? true : false}
      onClick={onClick}
      className={`${
        className || ''
      } bg-[#0044AA] disabled:bg-[#436eaf] rounded-md text-white text-sm py-2 px-4 w-full`}
    >
      {isFetching ? processingText : idleText}
    </button>
  );
}
