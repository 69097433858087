import React from 'react';

export function JustifyBetween({
  Left,
  Right,
  onClick,
  className,
}: {
  Left: JSX.Element;
  Right?: JSX.Element;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <div
      className={'w-full flex items-center justify-between ' + className}
      onClick={onClick}
    >
      <div className="flex items-center ">{Left}</div>
      <div className="flex-col text-right">{Right}</div>
    </div>
  );
}
