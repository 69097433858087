import React, { useEffect, useRef, useState } from 'react';

// @ts-ignore
import RecepientIcon from '../assets/svgs/recipient_icon.js';
// @ts-ignore
import RightArrow from '../assets/svgs/right_arrow.js';

import { Button, StatefulButton } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { useForm, useWatch } from 'react-hook-form';
import { FieldCard } from '../../src/components/FieldCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { PhoneInput } from '../../src/components/PhoneInput';
import { SelectTxnOptionParams } from './TransactionOption';
import ModalSheet from '../../src/components/BottomSheet';
import useVerifyPaymentMethodUserDetailsRequest from '../../src/hooks/usePaymentMethodDetailsValidate';
import { CountryFlagDetailsJustifyBetween } from '../../src/components/CountryFlagDetailsJustifyBetween';
// import { JustifyBetween } from 'src/components/JustifyBetween';
import { useStore } from 'src/store/store';
import { TextInput } from 'src/components/TextInput';
import { SelectInput } from 'src/components/SelectInput';
import { Recipient } from 'src/services/response-types/recipientsRes.js';

interface FormData {
  mobile_number: string;
  phone_code: number;
  account_no?: string;
  area?: string;
}

interface OtherData {
  recipient_id: string;
  customer_name: string;
}

export type RecepientSourceParams = OtherData &
  FormData &
  SelectTxnOptionParams;

export function RecepientSource() {
  const [showModalSheet, setShowModalSheet] = useState(false);
  const transactionData = useStore((state) => state.transactionData);
  const setTransactionData = useStore((state) => state.setTransactionData);
  const { register, handleSubmit, getValues, setValue, control } =
    useForm<FormData>({
      defaultValues: {
        account_no: transactionData?.account_no,
        mobile_number: transactionData?.mobile_number,
        phone_code: transactionData?.phone_code,
      },
    });
  let navigate = useNavigate();
  const submitFormRef = useRef<HTMLFormElement>();
  let { state }: { state: any } = useLocation();
  let paramsData: SelectTxnOptionParams = state;
  const accessToken = useStore((state) => state!.token?.access_token);
  let verificationData:
      | (Recipient & {
          verification_meta: { first_name: string; last_name: string };
        })
      | undefined,
    name: string | undefined;

  const { refetch, data, status, isFetching, remove } =
    useVerifyPaymentMethodUserDetailsRequest({
      accessToken: accessToken!,
      data: {
        country_code: paramsData.sending_to_country_code,
        payment_method_code: paramsData.payment_method_code,
        payment_method_id: paramsData.payment_method_id,
        meta: {
          mobile_number: `${getValues().mobile_number}`,
          phone_code: `${paramsData.phone_code}`,
          area: getValues().area,
          ...(getValues().account_no
            ? { account_no: getValues().account_no }
            : {}),
        },
      },
    });

  useWatch({ control, name: 'mobile_number' });
  useWatch({ control, name: 'account_no' });

  verificationData = data;
  name = `${data?.verification_meta?.first_name} ${data?.verification_meta?.last_name}`;

  useEffect(() => {
    if (status === 'success') {
      setShowModalSheet(true);
    }
  }, [data]);

  const onSubmit = handleSubmit((data: FormData) => {
    console.log(data);
    refetch({ throwOnError: true });
  });
  return (
    <div>
      <LayoutWithBottomBtn
        AppBarContent={
          <div className="flex p-5 justify-start items-center">
            <BackArrowButton />
            <p className="text-white font-semibold text-2xl pl-5">
              Recepient Source
            </p>
          </div>
        }
        MiddleContent={
          <>
            <section className="h-[calc(100%-130px)] bg-white">
              <form
                className="w-full"
                onSubmit={(_e) => {
                  onSubmit();
                }}
                // @ts-ignore
                ref={submitFormRef}
              >
                <FieldCard
                  FieldContent={
                    <PhoneInput
                      label="Phone Number"
                      name="mobile_number"
                      type="number"
                      textColor="black"
                      inputClassName="text-black"
                      defaultCountryCode={paramsData.sending_to_country_code}
                      phoneCodeFixed={true}
                      defaultPhoneCode={`${paramsData.phone_code}`}
                      register={register}
                      setValue={setValue}
                    />
                  }
                />

                {paramsData.payment_method_category_code == 'utility' && (
                  <FieldCard
                    FieldContent={
                      <TextInput
                        label="Account Number"
                        name="account_no"
                        type="text"
                        textColor="black"
                        register={register}
                      />
                    }
                  />
                )}

                {paramsData.payment_method_code == 'NWSC' && (
                  <FieldCard
                    FieldContent={
                      <SelectInput
                        label="Area"
                        name="area"
                        textColor="black"
                        register={register}
                        options={[
                          {
                            name: 'Kampala',
                            value: 'Kampala',
                          },
                          { name: 'Entebbe', value: 'Entebbe' },
                          { name: 'Iganga', value: 'Iganga' },
                          { name: 'Jinja', value: 'Jinja' },
                          { name: 'Kajjansi', value: 'Kajjansi' },
                          { name: 'Kawuku', value: 'Kawuku' },
                          { name: 'Lugazi', value: 'Lugazi' },
                          { name: 'Mukono', value: 'Mukono' },
                          {
                            name: 'Others',
                            value: 'Others',
                          },
                        ]}
                      />
                    }
                  />
                )}

                {/* <div className="pt-4 px-4 mt-4 mx-4 block">
                  <JustifyBetween
                    Left={
                      <p className="text-[#0044AA] font-normal text-base">
                        {'OR select from'}
                      </p>
                    }
                  />
                </div> */}
                {/* <div
                  className="flex items-center  justify-between shadow-lg m-4 rounded-lg"
                  onClick={() => {
                    navigate(
                      '/customer/transact/step-3/select-from-recipients',
                      {
                        state: {
                          ...paramsData,
                        } as SelectTxnOptionParams,
                      }
                    );
                  }}
                >
                  <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                    <div className="p-4 w-[100%] flex border-b-2">
                      <JustifyBetween
                        Left={
                          <div className="flex items-center">
                            <div className="ml-4 mr-4">
                              <RecepientIcon color="#0044AA" />
                            </div>
                            <div>
                              <p className="text-[#0044AA] font-normal text-lg">
                                {'Recipients'}
                              </p>
                            </div>
                          </div>
                        }
                        Right={<RightArrow color="#0044AA" />}
                      />
                    </div>
                  </div>
                </div> */}
              </form>
            </section>
          </>
        }
        BottomButtons={
          <StatefulButton
            type="submit"
            idleText="Continue"
            processingText="Processing..."
            className="h-12"
            isFetching={isFetching}
            onClick={() => {
              if (submitFormRef && submitFormRef.current) {
                // submitFormRef.current.preventDefault();
                console.log('Dispatching event');

                submitFormRef.current.dispatchEvent(
                  new Event('submit', {
                    cancelable: true,
                    bubbles: true,
                  })
                );
              }
              // navigate("/customer/transact/step-4/enter-amount");
            }}
          />
        }
      />
      <ModalSheet
        initialState={showModalSheet && data != undefined}
        updateParentState={setShowModalSheet}
        Body={
          <div className="px-8 justify-center items-center">
            <div className="text-center mt-2">You're going to transfer to:</div>
            <div className="justify-center inline-block w-[100%] items-center border-gray-400 px-10 py-4 mt-4 border-2 rounded-md">
              <CountryFlagDetailsJustifyBetween
                countryCode={paramsData?.sending_to_country_code}
                headerText={name}
              />
            </div>
            <div className="text-center my-2">Via</div>
            <div className="justify-center inline-block w-[100%] items-center border-gray-400 px-10 py-4 mt-4 border-2 rounded-md mb-6">
              <CountryFlagDetailsJustifyBetween
                countryCode={paramsData?.sending_to_country_code}
                headerText={paramsData.payment_method_name}
                bottomText={
                  getValues().account_no
                    ? `AC No: ${getValues().account_no}`
                    : `+${data?.phone_code} ${data?.mobile_number}`
                }
              />
            </div>
            <Button
              label="Continue"
              className="flex-1"
              bgColor="[#0044AA]"
              textColor="white"
              type="submit"
              onClick={() => {
                let navStateData = {
                  mobile_number: getValues()?.mobile_number,
                  account_no: getValues()?.account_no,
                  ...paramsData,
                  customer_name: name,
                  recipient_id: `${verificationData!.id}`,
                } as Partial<RecepientSourceParams>;

                setTransactionData(navStateData);
                remove();
                setShowModalSheet(false);

                navigate('/customer/transact/step-4/enter-amount', {
                  state: navStateData,
                });
              }}
            />
          </div>
        }
        HeaderCenterText={'Verify'}
        HeaderRightOnClick={() => {}}
        HeaderRightIcon={<></>}
        HeaderLeftOnClick={() => {}}
        HeaderLeftIcon={<></>}
      ></ModalSheet>
    </div>
  );
}
