import React from 'react';
import ReactCountryFlag from 'react-country-flag';

export function CurrencySymbolFlag({
  country,
}: {
  country: { currency?: string; countryCode: string };
}) {
  return (
    <div className="flex pl-4 items-center">
      <span>{country?.currency || 'UGX'}</span>
      <div className="mr-4"></div>
      <ReactCountryFlag
        countryCode={country.countryCode}
        svg
        style={{
          width: 50,
          height: 30,
        }}
      />
    </div>
  );
}

export function Flag({ countryCode }: { countryCode: string }) {
  return (
    <div className="">
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          width: 50,
          height: 30,
        }}
      />
    </div>
  );
}
