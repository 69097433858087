import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export type inputType = 'text' | 'password' | 'number';

export function TextInput({
  register,
  name,
  label,
  type,
  control,
  textColor,
  inputClassName,
}: {
  register: any;
  control?: any;
  name: string;
  label?: string;
  type: inputType;
  textColor?: string;
  inputClassName?: string;
}) {
  return (
    <div className="w-full mb-3">
      {label && (
        <label
          htmlFor=""
          className={`text-sm font-bold text-${textColor || 'white'} block`}
        >
          {label}
        </label>
      )}
      {type == 'number' ? (
        <Controller
          render={(props) => {
            return (
              <NumberFormat
                value={props.field.value}
                onValueChange={(target) => {
                  props.field.onChange(target.value);
                }}
                thousandSeparator
                isNumericString
                className={`w-full mt-4 p-2 rounded bg-opacity-20 bg-white ${inputClassName}  ${
                  textColor != 'white' ? 'border-2 border-gray-300' : 'border-0'
                }`}
              />
            );
          }}
          {...register(name)}
          name={name}
          defaultValue=""
          control={control}
        />
      ) : (
        <input
          {...register(name)}
          name={name}
          type={type}
          className={`w-full mt-4 p-2 rounded bg-opacity-20 bg-white ${inputClassName}  ${
            textColor != 'white' ? 'border-2 border-gray-300' : 'border-0'
          }`}
        />
      )}
    </div>
  );
}
