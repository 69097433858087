import React, { useRef } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { StatefulButton } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { FieldCard } from '../../src/components/FieldCard';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { useStore } from '../../src/store/store';
import { PhoneInput } from 'src/components/PhoneInput';
import { AmountFlag } from 'src/components/AmountFlag';
import { useQuery } from 'react-query';
import { makeTopupTransaction } from 'src/services/transactions';
import { CustomModal } from 'src/components/PendingModal';
import { useNavigate } from 'react-router-dom';

interface FormData {
  phoneNumber: string;
  amount: string;
  phone_code: string;
}

export function TopUpPageViaMobileMoney() {
  const { register, handleSubmit, control, getValues, setValue } =
    useForm<FormData>();
  const submitFormRef = useRef<HTMLFormElement>();
  const user = useStore((state) => state.user);
  const accessToken = useStore((state) => state.token?.access_token);
  const navigate = useNavigate();

  useWatch({ control, name: 'amount' });

  const makeTopupTransactionRequest = useQuery(
    'makeTopupTransaction',
    async () =>
      makeTopupTransaction({
        accessToken: accessToken!,
        body: {
          amount: Number(getValues().amount),
          method: 'mobile-money-uganda',
          mobile_number: user?.mobile_number!,
          phone_code: Number(user?.phone_code),
          transaction_type_code: 'topup',
        },
      }),
    {
      enabled: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (data: any) => {
        console.log(data);
      },
    }
  );

  const onSubmit = handleSubmit(() => {
    makeTopupTransactionRequest.refetch();
  });

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Top Up via Mobile Money
          </p>
        </div>
      }
      MiddleContent={
        <>
          {makeTopupTransactionRequest.isSuccess && (
            <CustomModal
              headerText="Topup initiated"
              bodyText="A prompt has been initiated on your phone, once you confirm, your account will be topped up. \n You may close this window."
              onClose={() => {
                makeTopupTransactionRequest.remove();
                navigate('/tabs', {
                  replace: true,
                });
              }}
            />
          )}
          <section className="h-[calc(100%-130px)] bg-white">
            <div>
              <div>
                <div className="w-full flex items-center justify-between">
                  <form
                    action=""
                    onSubmit={onSubmit}
                    className="w-full"
                    // @ts-ignore
                    ref={submitFormRef}
                  >
                    <div className="flex items-center h-[100%] justify-center ">
                      <div className="flex-1">
                        <FieldCard
                          FieldContent={
                            <PhoneInput
                              label="Phone Number"
                              name="mobileNumber"
                              type="number"
                              textColor="black"
                              inputClassName="text-black"
                              defaultCountryCode={user?.country_code}
                              mobileNumber={user?.mobile_number}
                              phoneCodeFixed={true}
                              defaultPhoneCode={`${user?.phone_code}`}
                              register={register}
                              setValue={setValue}
                            />
                          }
                        />

                        <FieldCard
                          FieldContent={
                            <div className="w-full">
                              <AmountFlag
                                countryCode={user?.country.code!}
                                label="You Send"
                                name="amount"
                                control={control}
                                type="number"
                                currency={user?.country.currency_code}
                                register={register}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      }
      BottomButtons={
        <StatefulButton
          type="submit"
          idleText="Top Up"
          processingText="Processing..."
          className="h-12"
          isFetching={makeTopupTransactionRequest.isFetching}
          onClick={() => {
            if (submitFormRef && submitFormRef.current) {
              // submitFormRef.current.preventDefault();
              console.log('Dispatching event');

              submitFormRef.current.dispatchEvent(
                new Event('submit', {
                  cancelable: true,
                  bubbles: true,
                })
              );
            }
          }}
        />
      }
    />
  );
}
