import React from 'react';
import { AppBar } from '../../src/components/AppBarContent';

export function LayoutWithBottomBtn({
  AppBarContent,
  MiddleContent,
  BottomButtons,
}: {
  AppBarContent: JSX.Element;
  MiddleContent: JSX.Element;
  BottomButtons: JSX.Element;
}) {
  return (
    <div className="h-[100vh] bg-gradient-to-b from-[#0044AA] to-[#001F4F] flex flex-col items-center">
      <div className="h-full bg-gradient-to-b flex flex-col items-center">
        {/* <section
                id="bottom-navigation"
                className="block fixed inset-x-0 top-0 z-10 bg-white shadow m-auto"
                style={{
                    width: 600,
                }}
            >
                <div className="h-[100%] w-[100%] bg-[#0044AA] flex flex-col px-[20px] rounded-br-[20px] rounded-bl-[20px]">
                    <div className="my-auto ">{AppBarContent}</div>
                </div>
            </section> */}

        <AppBar AppBarContent={AppBarContent} />

        <section className="w-[100vw] md:w-[600px] inset-x-0 z-1 bg-white shadow m-auto flex-1">
          {MiddleContent}
        </section>

        {/* if shown only tablet/mobile */}
        {/* <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">  */}
        <section
          id="bottom-navigation"
          className="w-[100vw] md:w-[600px] h-24 z-1 bg-white shadow m-auto"
        >
          <div id="tabs" className="flex justify-between h-6 p-3">
            {BottomButtons}
          </div>
        </section>
      </div>
    </div>
  );
}
