import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getPaymentSettings } from "../../src/services/paymentSettings";
import {
    SourceCountryOrDestinationCountry,
    SourceCountryOrDestinationCountryCodeType,
} from "../../src/services/response-types/settingsRes";
import * as _ from "lodash";
import { useStore } from "../../src/store/store";

export interface ReceiverCountryInputMap {
    baseCountryCode: SourceCountryOrDestinationCountryCodeType;
}

export default function useReceiverCountries({
    baseCountryCode,
}: ReceiverCountryInputMap) {
    const accessToken = useStore((state) => state!.token?.access_token);
    const [destinationCountries, setDestinationCountries] = useState<
        SourceCountryOrDestinationCountry[]
    >([]);
    const getPaymentSettingsRequest = useQuery(
        "getPaymentSettings",
        () =>
            getPaymentSettings({
                accessToken: accessToken!,
            }),
        {
            enabled: true,
        }
    );

    useEffect(() => {
        if (getPaymentSettingsRequest.isSuccess) {
            setDestinationCountries(
                _.uniqBy(
                    getPaymentSettingsRequest.data.payment_routes
                        .filter(
                            (paymentRoute) =>
                                paymentRoute.source_country.code ==
                                    baseCountryCode && paymentRoute.status == 1
                        )
                        .map(
                            (paymentRoute) => paymentRoute.destination_country
                        ),
                    "id"
                )
            );
        }
    }, [getPaymentSettingsRequest.data]);

    return { getPaymentSettingsRequest, destinationCountries };
}
