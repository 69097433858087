import './App.css';
import React from 'react';
// import { TabView } from "./pages/TabViewPage";
// import { WelcomePage } from "./pages/WelcomePage";
import { LoginPage } from './pages/LoginPage';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { RegisterPage } from './pages/RegisterPage';
import { WelcomePage } from './pages/WelcomePage';
// import { TopUpPage } from "./pages/TopUpPage";
import { TopUpPageViaCard } from './pages/TopUpPageViaCard';
import { TopUpPageViaMobileMoney } from './pages/TopUpPageViaMobileMoney';
import { ReceipientsPage } from './pages/ReceipientsPage';
import { RecepientDetailPage } from './pages/RecepientDetailPage';
import { ChangePassword } from './pages/ChangePassword';
import { EnterAmount } from './pages/EnterAmount';
import { TransactionSummary } from './pages/TransactionSummary';
import { SelectCountry } from './pages/SelectCountry';
import { TransactionOption } from './pages/TransactionOption';
import { RecepientSource } from './pages/RecepientSource';
import { PossibleRecipients } from './pages/PossibleRecipientsPage';
import { Account } from './pages/AccountPage';
import { VerifyAccount } from './pages/VerifyAccount';
import { TransactionHistory } from './pages/TransactionHistory';
import { TabPageSwitcher } from './pages/TabPageSwitcher';
import { Settings } from './pages/Settings';
import { Profile } from './pages/ProfilePage';
import { ErrorModal } from './components/ErrorModal';
import { Error } from './types/error';
import { TransactionDetail } from './pages/TransactionDetail';
import { AboutPage } from './pages/AboutPage';
import { RequestPasswordReset } from './pages/RequestPasswordReset';
// import LandingPageDesktopview from './pages/LandingPage';
// import { RegisterPage } from "./pages/RegisterPage";

export function App() {
  const [hasQueryError, setHasQueryError] = React.useState<Error | undefined>(
    undefined
  );

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: async (error: any, _query) => {
        console.log(error);

        if (error && error.message) {
          setHasQueryError(error);
        } else {
          setHasQueryError(error);
        }
      },
    }),
  });

  console.log(hasQueryError);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/customer/topup/card" element={<TopUpPage />} /> */}
        <Route path="/customer/topup/card" element={<TopUpPageViaCard />} />
        <Route path="/customer/account/about" element={<AboutPage />} />
        <Route
          path="/customer/topup/mm"
          element={<TopUpPageViaMobileMoney />}
        />
        <Route
          path="/customer/recipients/details"
          element={<RecepientDetailPage />}
        />
        <Route
          path="/customer/transact/step-1/select-country"
          element={<SelectCountry />}
        />
        <Route
          path="/customer/transact/step-2/transaction-option"
          element={<TransactionOption />}
        />
        <Route path="/reset-password" element={<RequestPasswordReset />} />
        <Route
          path="/customer/transact/step-3/select-recipient"
          element={<RecepientSource />}
        />
        <Route
          path="/customer/transact/step-3/select-from-recipients"
          element={<PossibleRecipients />}
        />
        <Route
          path="/customer/transact/step-4/enter-amount"
          element={<EnterAmount />}
        />
        <Route
          path="/customer/transact/step-5/transaction-summary"
          element={<TransactionSummary />}
        />
        <Route
          path="/customer/transaction-detail"
          element={<TransactionDetail />}
        />
        <Route path="/customer/recipients" element={<ReceipientsPage />} />
        <Route
          path="/customer/transaction-history"
          element={<TransactionHistory />}
        />
        <Route path="/customer/account" element={<Account />} />
        <Route path="/customer/account/settings" element={<Settings />} />
        <Route
          path="/customer/account/settings/change-password"
          element={<ChangePassword />}
        />
        <Route path="/customer/account/profile" element={<Profile />} />
        <Route path="/tabs" element={<TabPageSwitcher />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/verify-phone" element={<VerifyAccount />} />
        <Route path="/welcome" element={<WelcomePage />} />
      </Routes>
      {hasQueryError && (
        <ErrorModal
          error={hasQueryError}
          onClose={() => {
            setHasQueryError(undefined);
          }}
        />
      )}
    </QueryClientProvider>
  );
  // return <RegisterPage />;
  // return <WelcomePage />;
  // return <TabView />;
}
