import React, { useState } from 'react';

import { Button, StatefulButton } from '../../src/components/Button';
import { LayoutWithBottomBtn } from '../../src/layouts/LayoutWithBottomBtn';
import { JustifyBetween } from '../../src/components/JustifyBetween';
import { useNavigate } from 'react-router-dom';
import { BackArrowButton } from '../../src/components/BackArrowButton';
import { useQuery } from 'react-query';
import { makeTransaction } from '../../src/services/transactions';
import { TransactionRes } from '../../src/services/response-types/transactionRes';
import { EnterAmountParams } from './EnterAmount';
import { useLocation } from 'react-router-dom';
import { separateNumberWithCommas } from '../utils/helpers';
import { useStore } from '../../src/store/store';
import { without } from 'lodash';
import ModalSheet from '../../src/components/BottomSheet';
import { CountryFlagDetailsJustifyBetween } from '../../src/components/CountryFlagDetailsJustifyBetween';
import _ from 'lodash';

export function TransactionSummary() {
  let { state }: { state: any } = useLocation();
  const [showModalSheet, setShowModalSheet] = useState(false);
  const navigate = useNavigate();
  const setTransactionData = useStore((state) => state.setTransactionData);
  const user = useStore((state) => state.user);
  const accessToken = useStore((state) => state!.token?.access_token);
  let paramsData: EnterAmountParams = state;
  let paramDataKeys = Object.keys(paramsData) as (keyof EnterAmountParams)[];
  let paramDataKeysWithoutPaymentSettings: (keyof EnterAmountParams)[] =
    without(paramDataKeys, 'paymentSettings');

  console.log(
    'paramDataKeysWithoutPaymentSettings:',
    paramDataKeysWithoutPaymentSettings
  );

  const makeTransactionRequest = useQuery(
    'makeTransaction',
    async () =>
      makeTransaction({
        accessToken: accessToken!,
        body: {
          recipient_id: paramsData.recipient_id,
          transaction_type_code:
            paramsData.payment_method_category_code == 'utility'
              ? 'utility'
              : 'remittance',
          sending: `${paramsData.amount}`,
          sending_from: user?.country.code!,
          sending_to: paramsData.sending_to,
          payment_method_code:
            paramsData.payment_method_code.toLocaleLowerCase(),
        },
      }),
    {
      enabled: false,
      onError: (error: any) => {
        console.error(error);
      },
      onSuccess: async (data: TransactionRes) => {
        console.log(data);
      },
    }
  );

  const keyExtracts = [
    {
      title: 'Account Name',
      key: 'customer_name',
    },
    {
      prefix: 'phone_code',
      key: 'mobile_number',
      title: 'Mobile Number',
    },
    {
      title: 'Payment Method',
      key: 'payment_method_name',
    },
    {
      title: 'Account Number',
      key: 'account_no',
    },

    {
      key: 'amount',
      title: 'Amount',
      prefix: 'sending_from_currency',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
    {
      prefix: 'sending_from_currency',
      title: 'Service Fee',
      key: 'service_fee',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
    {
      key: 'amount_to_receive',
      title: 'Amount To Receive',
      prefix: 'sending_to_currency',
      manipulator: (value: string) => {
        return separateNumberWithCommas(value);
      },
    },
  ];

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Transaction Summary
          </p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-full">
              <div className="flex items-center  justify-between shadow-lg m-4 rounded-lg">
                <div className="flex flex-col items-center  w-[100%] h-[100%] justify-center ">
                  {keyExtracts.map(function (item, index) {
                    if (_.get(paramsData, item.key)) {
                      return (
                        <div key={index} className="p-4 w-[100%] border-b-2">
                          <JustifyBetween
                            Left={
                              <p className="text-[#0044AA] font-normal text-base">
                                {item.title}
                              </p>
                            }
                            Right={
                              <p className="text-[#0044AA] font-normal text-base">
                                {item.prefix &&
                                  _.get(paramsData, item.prefix, 'N/A')}{' '}
                                {item.manipulator
                                  ? item.manipulator(
                                      _.get(paramsData, item.key, 'N/A')
                                    )
                                  : _.get(paramsData, item.key, 'N/A')}
                              </p>
                            }
                          />
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}

                  {/* {paramDataKeysWithoutPaymentSettings.map((param, index) => (
                    <div className="p-4 w-[100%] border-b-2" key={index}>
                      <JustifyBetween
                        Left={
                          <p className="text-[#0044AA] font-normal text-base">
                            {titleCase(param)}
                          </p>
                        }
                        Right={
                          <p className="text-[#0044AA] font-normal text-base">
                            {paramsData[param]}
                          </p>
                        }
                      />
                    </div>
                  ))} */}
                </div>
              </div>
              <div className="p-4 m-4">
                <JustifyBetween
                  Left={
                    <p className="text-[#0044AA] font-normal text-base">
                      {'Balance after transaction'}
                    </p>
                  }
                  Right={
                    <p className="text-[#0044AA] font-normal text-base">
                      {`${
                        paramsData.sending_from_currency
                      } ${separateNumberWithCommas(paramsData.balance_after)}`}
                    </p>
                  }
                />
              </div>
            </div>
            <ModalSheet
              initialState={showModalSheet && makeTransactionRequest.isSuccess}
              updateParentState={setShowModalSheet}
              Body={
                <div className="px-8 justify-center items-center">
                  <div className="text-center mt-2">
                    Your Transfer has been sent to
                  </div>
                  <div className="justify-center inline-block w-[100%] items-center border-gray-400 px-10 py-4 mt-4 border-2 rounded-md">
                    <CountryFlagDetailsJustifyBetween
                      countryCode={paramsData?.sending_to_country_code}
                      headerText={paramsData.customer_name}
                    />
                  </div>
                  <div className="text-center my-2 mt-4">Via</div>
                  <div className="justify-center inline-block w-[100%] items-center border-gray-400 px-10 py-4 mt-4 border-2 rounded-md mb-6">
                    <CountryFlagDetailsJustifyBetween
                      countryCode={paramsData?.sending_to_country_code}
                      headerText={paramsData.payment_method_name}
                      bottomText={
                        paramsData.account_no
                          ? `AC No: ${paramsData.account_no}`
                          : `+${paramsData?.phone_code} ${paramsData?.mobile_number}`
                      }
                    />
                  </div>
                  <div className="text-center my-2 mt-4">Transfer Amount</div>
                  <div className="justify-center inline-block w-[100%] items-center border-gray-400 px-10 py-4 mb-4 border-2 rounded-md">
                    <JustifyBetween
                      Left={
                        <p className="text-[#0044AA] font-normal text-base">
                          {'Amount'}
                        </p>
                      }
                      Right={
                        <p className="text-[#0044AA] font-normal text-base">
                          {/* @ts-ignore */}
                          {separateNumberWithCommas(
                            makeTransactionRequest.data?.amount || 0
                          ) || 'N/A'}
                        </p>
                      }
                    />
                  </div>
                  <Button
                    label="Close"
                    className="flex-1"
                    bgColor="[#0044AA]"
                    textColor="white"
                    type="submit"
                    onClick={() => {
                      navigate('/tabs', {
                        replace: true,
                      });
                      setTransactionData({});
                    }}
                  />
                </div>
              }
              HeaderCenterText={'Transaction Successful'}
              HeaderRightOnClick={() => {}}
              HeaderRightIcon={<></>}
              HeaderLeftOnClick={() => {}}
              HeaderLeftIcon={<></>}
            ></ModalSheet>
          </section>
        </>
      }
      BottomButtons={
        <StatefulButton
          type="submit"
          idleText="Complete Transaction"
          processingText="Processing..."
          className="h-12"
          isFetching={makeTransactionRequest.isFetching}
          onClick={() => {
            // navigate("/customer/transact/step-3/select-recipient");
            setShowModalSheet(true);
            makeTransactionRequest.refetch({ throwOnError: true });
          }}
        />
      }
    />
  );
}
