import React, { useRef } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../components/TextInput';
import { StatefulButton } from '../components/Button';
import { LayoutWithBottomBtn } from '../layouts/LayoutWithBottomBtn';
import { FieldCard } from '../components/FieldCard';
import { BackArrowButton } from '../components/BackArrowButton';
import { useStore } from 'src/store/store';
import useUpdateProfile from 'src/hooks/useUpdateProfile';

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
}

export function Profile() {
  const user = useStore((state) => state.user);
  const { register, handleSubmit, getValues, control } = useForm<FormData>({
    defaultValues: {
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
    },
  });
  const submitFormRef = useRef<HTMLFormElement>();
  const { userId, accessToken } = useStore((state) => {
    return {
      userId: state?.user?.id,
      accessToken: state?.token?.access_token,
    };
  });

  useWatch({ control, name: 'first_name' });
  useWatch({ control, name: 'last_name' });
  useWatch({ control, name: 'email' });

  const { refetch, isFetching } = useUpdateProfile({
    userId: `${userId!}`,
    accessToken: accessToken!,
    data: {
      first_name: getValues().first_name,
      last_name: getValues().last_name,
      email: getValues().email,
    },
  });
  const onSubmit = handleSubmit((_data: FormData) => {
    console.log(_data);

    refetch({ throwOnError: true });
  });

  return (
    <LayoutWithBottomBtn
      AppBarContent={
        <div className="flex p-5 justify-start items-center">
          <BackArrowButton />
          <p className="text-white font-semibold text-2xl pl-5">
            Update Profile
          </p>
        </div>
      }
      MiddleContent={
        <>
          <section className="h-[calc(100%-130px)] bg-white">
            <div className="w-[100vw] md:w-[600px] flex items-center justify-between">
              <form
                action=""
                onSubmit={onSubmit}
                className="w-[100vw] md:w-[600px]"
                // @ts-ignore
                ref={submitFormRef}
              >
                <div className="flex items-center h-[100%] justify-center ">
                  {/* <img
                                                        className="w-10 h-10 rounded-lg"
                                                        src={user.avatar}
                                                        alt={user.name}
                                                    /> */}
                  <div className="flex-1">
                    <FieldCard
                      FieldContent={
                        <TextInput
                          label="First Name"
                          name="first_name"
                          type="text"
                          textColor="black"
                          inputClassName="text-black"
                          register={register}
                        />
                      }
                    />
                    <FieldCard
                      FieldContent={
                        <TextInput
                          label="Last Name"
                          name="last_name"
                          type="text"
                          textColor="black"
                          inputClassName="text-black"
                          register={register}
                        />
                      }
                    />
                    <FieldCard
                      FieldContent={
                        <TextInput
                          label="Email"
                          name="email"
                          type="text"
                          textColor="black"
                          inputClassName="text-black"
                          register={register}
                        />
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
        </>
      }
      BottomButtons={
        <StatefulButton
          type="submit"
          idleText="Update Profile"
          processingText="Processing..."
          className="h-12"
          isFetching={isFetching}
          onClick={() => {
            if (submitFormRef && submitFormRef.current) {
              // submitFormRef.current.preventDefault();
              console.log('Dispatching event');

              submitFormRef.current.dispatchEvent(
                new Event('submit', {
                  cancelable: true,
                  bubbles: true,
                })
              );
            }
          }}
        />
      }
    />
  );
}
